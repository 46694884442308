import React from 'react';
import { object, objectOf, string, arrayOf, any } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { trackUserInteraction } from '../../../helpers/datalayer-utils';
import './authors.module.css';
import { AccountEditOutlineIcon } from '../../atoms/material-icons';

const TwitterHandle = ({ handle }) => (
  <a
    href={`https://twitter.com/${handle}`}
    target="_blank"
    rel="noreferrer noopener"
    styleName="author-link author-twitter-handler"
  >
    {handle.includes('@') ? '' : '@'}
    {handle}
  </a>
);

TwitterHandle.propTypes = {
  handle: string.isRequired
};

const Authors = ({ authors, datalayerFields }) => {
  if (authors === null) return null;
  return (
    <div styleName="author-info">
      <AccountEditOutlineIcon color="var(--fourth-color)" size="20px" />
      <ul styleName='author-handlers'>
        {authors.map((author, index) => (
          <li
            key={`author-${author.id}`}
            styleName="author"
            onClick={() =>
              trackUserInteraction(
                Object.assign({}, datalayerFields, {
                  category: 'Article Page',
                  action: author.name,
                  cd23: 'middle author module container'
                })
              )
            }
          >
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={`/author/${author.id}/${author.slug}`}
              styleName="author-link author-name"
            >
              {index === authors.length - 2
                ? `${author.name} &`
                : index === authors.length - 1
                ? `${author.name}`
                : `${author.name}, `}
            </a>
            {author['twitter-handle'] && <TwitterHandle handle={author['twitter-handle']} />}
          </li>
        ))}
      </ul>
    </div>
  );
};

Authors.propTypes = {
  authors: arrayOf(object),
  datalayerFields: objectOf(any).isRequired
};

Authors.defaultProps = {
  authors: []
};

const mapStateToProps = state => ({
  bookmark: get(state, ['qt', 'config', 'features', 'bookmark', 'enabled'], false)
});

export default connect(mapStateToProps, {})(Authors);
