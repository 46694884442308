import PropTypes from 'prop-types';
import React from 'react';
import { BQLink } from '../bqlink';
import Icon from '@mdi/react';
import { mdiHome } from '@mdi/js';
import './breadcrumb-basic.m.css';
import Marquee from 'react-fast-marquee';
import { isMobile } from 'react-device-detect';

const BreadcrumbBasic = (props) => {
  let sectionName = !props?.podcastPage ? 'Exclusives' : 'Podcasts';
  let sectionSlug = !props?.podcastPage ? 'exclusive' : '';

  if (Array.isArray(props?.story?.sections)) {
    for (let i = 0; i < props?.story?.sections?.length; i++) {
      const section = props?.story?.sections[i];
      if ('display-name' in section) {
        sectionName = section['display-name'];
        sectionSlug = section.slug;
        break;
      }
    }
  }

  const Breadcrumb = () => (
    <div
      styleName={`breadcrumb ${!props?.podcastPage ? 'mb-breadcrumb' : ''}`}
      className="container"
      style={{ color: props.color }}
    >
      <BQLink story={props?.story} href={'/?src=story-page'}>
        <Icon path={mdiHome} size="22px" color={props?.color ? props.color : '#5c6d77'} />
      </BQLink>
      <span styleName="dot" style={{ background: props?.color ? props.color : '#5c6d77' }}></span>
      <span>
        {props?.podcastPage ? (
          <div>{sectionName}</div>
        ) : (
          <BQLink href={`/${sectionSlug}?src=story-page`}>{sectionName}</BQLink>
        )}
      </span>
      <span styleName="dot" style={{ background: props.color }}></span>
      <span styleName="breadcrumb-headline" style={{ color: props.color }}>
        {!props?.podcastPage ? props.story?.headline : props?.podcastName}
      </span>
      {props?.episode && (
        <>
          <span styleName="dot" style={{ background: props.color }}></span>
          <span styleName="breadcrumb-headline" style={{ color: props.color }}>
            {props.episode}
          </span>
        </>
      )}
    </div>
  );

  return (
    <div
      styleName="floating-breadcrumb"
      className={`${props.classes} floating-breadcrumb-global`}
      style={{ background: props.bgColor, color: props.color }}
    >
      {props?.episode && isMobile ? (
        <Marquee>
          <Breadcrumb />
        </Marquee>
      ) : (
        <Breadcrumb />
      )}
    </div>
  );
};

BreadcrumbBasic.propTypes = {
  story: PropTypes.object,
  bgColor: 'var(--gallery)',
  color: '#5c6d77',
  classes: 'desktop-only',
  episode: undefined,
};

export default BreadcrumbBasic;
