import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { string, any, object, bool } from 'prop-types';
import { LazyCollection, WithLazy } from '@quintype/components';
import queryString from 'query-string';
import ClockIcon from '../../../atoms/icons/clock-icon';
import storyElementWithCustomTemplates from '../../../story/story-elements/index';
import { shareIconDL } from '../../../../helpers/datalayer-utils';
import {
  formatDate,
  formatPublishedDate,
  getDDMMYYYY,
  getDuration,
  getStoryDate,
  getUserStatus,
  syndicatedSource,
} from '../../../../helpers/utils';
import { getCollectionTemplate } from '../../../../helpers/get-collection-template';

import get from 'lodash/get';
import './single-video-with-playlists.m.css';
import Authors from '../../../story/authors/authors';
import { ClockOutlineIcon } from '../../../atoms/material-icons';
import PublishedInfo from '../../../story/published-info/published-info';
import Share from '../../../story/share/share';
import { trackUserInteraction } from '../../../../helpers/datalayer-utils';
import BreadcrumbBasic from '../../../atoms/breadcrumb-basic';
import AdWithPlaceholder from '../../../molecules/ad-with-placeholder';
import { isDesktop } from 'react-device-detect';
import VideoTitle from '../../../molecules/titles/videos';
import ViewAll from '../../../atoms/view-all';
import VideoCardImageHeadlineAuthor from '../../../molecules/video-card-image-headline-author';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { getSSORedirectUrl } from '../../../../helpers/subscription-helper';
import MailmodoNewsLetter from '../../../molecules/newsletter/mailmodo-news-letter';
import StoryListSlider from '../../../collection-templates/story-list-slider';
import Icon from '@mdi/react';
import { mdiShare } from '@mdi/js';
import BookmarkShare from '../../../story/share/bookmark-share';

const storyShareClickHandler = (storyContentId, storyUrl) => (event) =>
  global.app.registerStoryShare(
    storyContentId,
    event.currentTarget.getAttribute('data-social-media-type'),
    storyUrl,
  );

function addLinkToCopy() {
  var bodyElement = document.getElementsByTagName('body')[0];
  var selection = window.getSelection();
  var pagelink =
    "<br /><br /> Read more at: <a href='" +
    document.location.href +
    "'>" +
    document.location.href +
    '</a><br />Copyright &copy; NDTV Profit';
  var copytext = selection.toString().substring(0, 400) + pagelink;
  var newdiv = document.createElement('div');
  newdiv.style.position = 'absolute';
  newdiv.style.left = '-99999px';
  bodyElement.appendChild(newdiv);
  newdiv.innerHTML = copytext;
  selection.selectAllChildren(newdiv);
  window.setTimeout(function () {
    bodyElement.removeChild(newdiv);
  }, 0);
}

const SingleVideoStoryWithPlayLists = ({
  story,
  singleVideoPageCollection,
  showSingleVidibleVideo,
  bookmark,
  publisherUrl,
  testPage = false,
  newsLetterList,
  ...props
}) => {
  const duration = story.cards[0]['story-elements'][0]?.metadata?.['duration-millis'] || false;
  const [initialLoading, setInitialLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [oneTapDisable, setOneTapDisable] = useState(true);
  const isLoggedIn = useSelector((state) => get(state, ['userData', 'isLoggedIn'], false));
  const SSO_ONE_TAP_URL = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'subscription', 'google_one_tap_url'], null),
  );
  const SSO = useSelector((state) => get(state, ['qt', 'config', 'integrations', 'sso'], null));
  const userData = useSelector((state) => get(state, ['userData']));
  const newsletter = newsLetterList?.find(n => n.data_key === 'Talking');


  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialLoading(false);
    }, 250);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if(!testPage) {
      wretch('/api/v1/collections/in-the-news-videos')
      .get()
      .json(async (response) => {
        setVideos(get(response, ['items']).slice(0, 4));
      });
    }
  }, []);

  useEffect(() => {
    // TODO: Reset function when unmounting
    document.oncopy = addLinkToCopy;
    const queryObject = queryString.parse(window.location.search);

    if (queryObject.utm_source === 'wru') {
      const datalayerFields = {
        category: 'article page',
        action: 'Recommended for you',
        label: get(story, ['story', 'headline'], ''),
        event: 'event articlepage',
        cd22: 'link',
        cd23: 'bottom more on this topic',
        cd24: 'NA', // element id
        cd26: syndicatedSource(get(story, 'story', null)),
        cd31: get(story, ['story', 'story-template'], 'text'),
        cd35: 'wru',
        cd34: 'NA', // source of the user
      };
      trackUserInteraction(datalayerFields);
    }
    if (window.history.length === 1) {
      const _datalayerFields = {
        event: 'event Back button clicked',
        label: 'back button',
        action: 'click',
      };
      (function (window, location) {
        history.replaceState(null, document.title, location.pathname + '#!/homepage');
        history.pushState(null, document.title, location.pathname);

        window.addEventListener(
          'popstate',
          function () {
            if (location.hash === '#!/homepage') {
              history.replaceState(null, document.title, location.pathname);
              trackUserInteraction(_datalayerFields);
              setTimeout(function () {
                location.replace('https://www.ndtvprofit.com/');
              }, 0);
            }
          },
          false,
        );
      })(window, location);
    }

    window.localStorage.setItem(
      'storyInfo',
      JSON.stringify({
        "story-template": story["story-template"],
        "updated-at": story["updated-at"]
      }),
    );
  }, []);

  useEffect(() => {
    const temp = setTimeout(() => setOneTapDisable(false), 5000);
    return () => clearTimeout(temp);
  }, []);

  let scrollPercentage = 0;
  const calculateScrollPercentage = () => {
    if (typeof window !== 'undefined') {
      const scrollTop = window.scrollY;

      const documentHeight = document.documentElement.scrollHeight - window.innerHeight;

      if (documentHeight > 0) {
        scrollPercentage = (scrollTop / documentHeight) * 100;
        localStorage.setItem('scrollPercentage', scrollPercentage);
      }
    }
  };

  const handleScroll = () => {
    calculateScrollPercentage();
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);
  const {
    id = 'NA',
    'author-name': authorName = 'NA',
    'last-published-at': lastPublishedAt,
    'updated-at': updatedAt,
    tags = [],
  } = story;
  const finalUpdatedAt = lastPublishedAt || updatedAt || 'NA';
  const publishedAt = get(story, 'first-published-at', get(story, 'published-at', 'NA'));
  const publishedDateTime = publishedAt !== 'NA' ? formatPublishedDate(publishedAt) : 'NA, NA';
  const [time, date] = publishedDateTime?.split(',');
  const publishedTime = time?.trim() || 'NA';
  const publishedDate = getDDMMYYYY(publishedAt) || 'NA';
  const updatedAtDateTime = getDDMMYYYY(finalUpdatedAt) || 'NA';
  let category = 'business';
  if (typeof window !== 'undefined') {
    category = window.location.pathname.substring(1).split('/')[0] || 'business';
  }
  const pageType = 'story';
  const source = get(story, ['metadata', 'story-attributes', 'syndicatedfrom', '0'], 'NDTV Profit');
  const storyTemplate = get(story, ['story-template'], '');
  const contentType =
    storyTemplate === 'video' || storyTemplate === 'ndtv-video'
      ? 'video'
      : storyTemplate === 'web-story'
        ? 'web-story'
        : 'article';
  const pageTitle = get(story, ['headline'], 'NA');
  const authors = authorName;
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const timeoutRef = useRef(null);
  const shareContainerRef = useRef(null);
  const datalayerFields = {
    page_title: pageTitle,
    page_template: pageType || 'NA',
    authors: authors || 'NA',
    agency: source || 'NA',
    category: category || 'NA',
    publish_date: publishedDate || 'NA',
    publish_time: publishedTime || 'NA',
    last_update_date: updatedAtDateTime || 'NA',
    tvc_article_id: id || 'NA',
    sub_category: 'NA',
    section_name: 'story-header' || 'NA',
    ContentType: contentType,
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: getUserStatus(),
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
  };

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      if (credentialResponse.credential) {
        getSSORedirectUrl(new URL(window?.location?.href)).then((redirectUrl) => {
          window.location.href = `${SSO_ONE_TAP_URL}?credential=${credentialResponse.credential}&clientId=${SSO.client_id}&clientSecret=${SSO.client_secret}&clientRedirecturl=${redirectUrl}`;
        });
      } else {
        console.log('Unable to receive credential');
      }
    },
    onError: () => {
      console.log('Google one tap Login Failed');
    },
    cancel_on_tap_outside: true,
    disabled: !!isLoggedIn,
    use_fedcm_for_prompt: true,
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (shareContainerRef.current && !shareContainerRef.current.contains(event.target)) {
        setIsShareModalOpen(false);
      }
    };

    const handleScroll = () => {
      setIsShareModalOpen(false);
    };

    if (isShareModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('scroll', handleScroll);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isShareModalOpen]);

  const handleShareClick = () => {
    if (isMobile) {
      if (navigator.share) {
        navigator.share({
          title: story?.title || document?.title || '',
          text: story?.summary || '',
          url: `${publisherUrl}/${story.slug}` || '#'
        })
        .catch(error => console.log('Error sharing:', error));
      } else {
        setIsShareModalOpen(!isShareModalOpen);
      }
    } else {
      setIsShareModalOpen(!isShareModalOpen);
    }
  };

  return (
    <>
      <BreadcrumbBasic story={story} />
      <div className="story-page container" styleName="single-video-container">
        <div className="desktop-only">
          {isDesktop && (
            <div styleName="add-with-grey-background">
              <div className="responsive-ad content-center">
                <AdWithPlaceholder
                  adtype="AboveArticleHeadline"
                  height="90px"
                  width="970px"
                  adUnit="Top_728x90"
                  sizes={[
                    [728, 90],
                    [970, 90],
                  ]}
                />
              </div>
            </div>
          )}
        </div>
        <div styleName="heading-wrapper">
          <div className="container">
            <h1 styleName="story-title">{story.headline}</h1>
            <div styleName={'story-content-head'}>
              <div styleName="author-and-published-time">
                <Authors
                  storyId={story.id}
                  authors={story.authors}
                  datalayerFields={datalayerFields}
                  alt="BQ"
                />
                <span
                  styleName="story-published-date"
                  dateTime={new Date(story['first-published-at'])
                    .toISOString()
                    .replace('Z', '+05:30')}
                >
                  <ClockOutlineIcon size="18px" color="var(--fourth-color)" />
                  <span styleName="story-date">{getStoryDate(story['last-published-at'])} </span>
                  <span className="icon-ic_info_outline" styleName="story-date-info-icon">
                    <span className="i-icon">i</span>
                  </span>
                  <div styleName="popover-story-dates">
                    <PublishedInfo
                      publishedAt={story['first-published-at']}
                      updatedAt={story['last-published-at']}
                    />
                  </div>
                </span>
              </div>

              {/* Social share option beside author details */}
              <div styleName="share-floating-expanded" ref={shareContainerRef}>
                <img
                  src={props?.googlenews_icon}
                  title="Google News"
                  height="46vw"
                  alt="google-news-follow"
                  onClick={() => {
                    window.open(
                      'https://news.google.com/publications/CAAqBwgKMKr0kAsw9JylAw?hl=en-IN&gl=IN&ceid=IN%3Aen',
                      '_blank',
                    ),
                      shareIconDL('Google News', story, userData, 'story header');
                  }}
                />
                <div styleName="icon-wrapper-bookmark">
                  <BookmarkShare />
                </div>
                <div styleName="share-btn"
                  onClick={() => handleShareClick()}
                  onMouseEnter={() => setIsShareModalOpen(true)}
                >
                  <Icon path={mdiShare} size='24px' />
                </div>
                {isShareModalOpen && !isMobile && (
                  <Share
                    datalayerFields={datalayerFields}
                    title={story.headline}
                    url={story.slug}
                    summary={story.summary}
                    services={['whatsapp', 'twitter', 'facebook', 'telegram']}
                    onClick={storyShareClickHandler(
                      story['story-content-id'],
                      `${publisherUrl}/${story.slug}`,
                    )}
                    source="story-content-head"
                    expanded={true}
                    alignType="horizontal"
                    showToggleWhenExpanded={false}
                    bookmark={false}
                    storyId={story['story-content-id']}
                    isModal={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar">
          <div styleName="video-wrapper">
            <div styleName="video">
              {story.cards.map((card, index) => (
                <div key={index} className="storyvideoplayer-placeholder">
                  {card['story-elements'].map((element) =>
                    storyElementWithCustomTemplates(card, element, story),
                  )}
                </div>
              ))}
              {duration && (
                <div styleName="time-stamp">
                  <ClockIcon />
                  <span>{getDuration(duration || 0)}</span>
                </div>
              )}
            </div>
          </div>
          {!isMobile && (
            <div styleName="videos-wrapper">
              {initialLoading ? (
                <div className="loading-container"></div>
              ) : testPage ? (
                <div styleName="ad-mail-wrapper">
                  <div
                    className="responsive-ad content-center desktop-only"
                    styleName="video-story-ad"
                  >
                    <AdWithPlaceholder
                      adtype="SpFwMrec1"
                      height="280px"
                      width="336px"
                      adUnit="Top_300x250"
                      sizes={[
                        [300, 250],
                        [336, 280],
                      ]}
                    />
                  </div>
                  <div className="desktop-only">
                    <MailmodoNewsLetter
                      title={newsletter.title}
                      heading={newsletter.subTitle}
                      footerText1=""
                      footerText2="View All Newsletters"
                      page="home"
                    />
                  </div>
                </div>
              ) : (
                <WithLazy>
                  {() => (
                    <>
                      <div styleName="videos-title" className="title-with-view-all">
                        <VideoTitle />
                        <ViewAll isTitle={true} />
                      </div>
                      <div styleName="videos">
                        {videos?.map((item, index) => (
                          <VideoCardImageHeadlineAuthor key={index} story={item.story} />
                        ))}
                      </div>
                    </>
                  )}
                </WithLazy>
              )}
            </div>
          )}
        </div>
        {isMobile && testPage && (
          <div className="responsive-ad mobile-only content-center" styleName="video-story-ad">
            <AdWithPlaceholder
              adtype="SpFwMrec1"
              height="280px"
              width="336px"
              adUnit="Top_300x250"
              sizes={[
                [300, 250],
                [336, 280],
              ]}
            />
          </div>
        )}
        {!isMobile && testPage && (
          <div styleName="ad-after-video">
            <AdWithPlaceholder
              adtype="AdAfterVideoStory"
              width="728px"
              height="90px"
              adUnit="Mid_728x90_1"
              sizes={[[728, 90]]}
            />
          </div>
        )}
        {testPage ? (
          singleVideoPageCollection.items
            .slice(0, isMobile ? 5 : 3)
            .map((item, index) => (
              <StoryListSlider
                collection={item}
                index={index}
                testPage={testPage}
                isMobile={isMobile}
              />
            ))
        ) : singleVideoPageCollection ? (
          <LazyCollection
            showSingleVidibleVideo={showSingleVidibleVideo}
            collection={singleVideoPageCollection}
            collectionTemplates={getCollectionTemplate}
            lazyAfter={1}
          />
        ) : null}
      </div>
    </>
  );
};

SingleVideoStoryWithPlayLists.propTypes = {
  story: object,
  singleVideoPageCollection: any,
  showSingleVidibleVideo: bool,
  bookmark: bool,
  publisherUrl: string.isRequired,
};

const mapStateToProps = (state) => ({
  publisherUrl: state.qt.config['sketches-host'],
  textSelectionEnabled: state.qt.config.features.story.text_selection.enabled,
  amazon_polly: get(state.qt, ['config', 'features', 'amazon_polly', 'enabled'], false),
  bookmark: get(state, ['qt', 'config', 'features', 'bookmark', 'enabled'], false),
  googlenews_icon: get(state, ['qt', 'config', 'publisher', 'cdn-images', 'googlenews'], ''),
  newsLetterList: get(state, ['qt', 'config', 'features', 'newsLetterList'], []),
});

export default connect(mapStateToProps, {})(SingleVideoStoryWithPlayLists);
