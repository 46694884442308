import React from 'react';

import { objectOf, any } from 'prop-types';
import { BQLink } from '../../../atoms/bqlink';
import { isStoryExclusive } from '../../../../helpers/story-helpers';
import { FourStarIcon } from '../../../atoms/material-icons';
import { formatPublishedDate, getDDMMYYYY, getUserStatus } from '../../../../helpers/utils';
import get from 'lodash/get';
import SvgIcon from '../../../atoms/svg-icon';
import { useSelector } from 'react-redux';

import './also-read.m.css';
import { DATE_FORMATS } from '../../../../constants';
import { isMobile } from 'react-device-detect';

const AlsoRead = ({ element, story }) => {
  const linkedStoryId = element.metadata['linked-story-id'];
  if (!story['linked-stories'] || !story['linked-stories'][linkedStoryId]) return null;
  const storyUrl =
    story['linked-stories'] && '/' + story['linked-stories'][linkedStoryId].slug + '?src=also_read';
  const userData = useSelector((state) => get(state, ['userData']));
  const publishedAt = element.metadata['linked-story']?.['last-published-at'] || 'NA';
  const publishedDateTime = publishedAt !== 'NA' ? formatPublishedDate(publishedAt) : 'NA, NA';
  const updatedAt = element?.metadata?.['linked-story']['updated-at'];
  const [time, date] = publishedDateTime?.split(',');
  const publishedTime = time.trim() || 'NA';
  const publishedDate = publishedAt !== 'NA' ? (getDDMMYYYY(publishedAt)) : 'NA';
  const updatedAtDateTime = updatedAt !== 'NA' ? (getDDMMYYYY(updatedAt)) : 'NA';
  const slug = element?.metadata?.['linked-story']?.['slug'] || 'NA';
  const sectionName = slug !== 'NA' ? slug.split('/')[0] : 'NA';
  const source =
    get(story, ['metadata', 'story-attributes', 'syndicatedfrom', '0'], 'NDTV Profit');
  const storyTemplate = element?.metadata?.['linked-story']?.['story-template'] || 'NA';
  const contentType = (storyTemplate === 'video' || storyTemplate === 'ndtv-video') ? 'video' : 'article';
  const authors = element?.metadata?.['linked-story']?.['author-name'] || 'NA';
  const id = element?.metadata?.['linked-story-id'] || 'NA';
  const handleClick = (headline) => {
    const baseData = {
      event: 'also_read_interaction',
      page_template: "story",
      page_title: headline || 'NA',
      authors: authors || 'NA',
      agency: source || 'NA',
      category: sectionName || 'NA',
      publish_date: publishedDate || 'NA',
      publish_time: publishedTime || 'NA',
      last_update_date: updatedAtDateTime || 'NA',
      tvc_article_id: id || 'NA',
      sub_category: 'NA',
      section_name: 'also read',
      ContentType: contentType,
      user_id: userData?.userProfile?.userId || 'NA',
      user_status: getUserStatus(),
      login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
      language_hit: 'english',
      Platform: isMobile ? 'mobile' : 'desktop',
      SiteName: 'https://www.ndtvprofit.com'
    };

    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };
  return (
    <>
      <div className="also-read-container">
        <div className="also-read-heading">
          <h4 className="heading">ALSO READ</h4>
        </div>
        <h5>
          <a
            className="story-element-text-also-read__link"
            href={storyUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => handleClick(element.text)}
          >
            {element.text}
            {isStoryExclusive(story) && (
              <span className="exclusive-icon">
                <FourStarIcon color="#CE9445" size="16px" />
              </span>
            )}
          </a>
        </h5>
      </div>
      <div styleName="bb-opinion-container">
        <div className="bbo-header">
          <SvgIcon
            type="bloomberg1"
            width="136"
            height="26"
            viewBox="0 0 112.479 17.347"
            iconStyle={{ fill: 'var(--first-color)' }}
          />
          <span>Opinion</span>
        </div>
        <div className="bbo-content">
          <div className="title">{element.text}</div>
          {element.description && <div className="description">{element.description}</div>}
          <div className="read-more">
            <BQLink
              className="story-element-text-also-read__link"
              href={storyUrl}
              target="_blank"
              rel="noreferrer"
            >
              Read More
            </BQLink>
          </div>
        </div>
      </div>
    </>
  );
};

AlsoRead.propTypes = {
  element: objectOf(any).isRequired,
  story: objectOf(any).isRequired
};

export default AlsoRead;
