import React from 'react';
import { objectOf, any, string } from 'prop-types';
import { connect } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import { Modal } from '../../../molecules/modal/index';
import { isMobile } from 'react-device-detect';
import './attachment-preview.module.css';

class AttachmentPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      numPages: null,
      pageNumber: 1,
      safariVersion: null,
    };
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }

  openPopup() {
    this.setState({ isPopupOpen: true });
  }

  closePopup() {
    this.setState({ isPopupOpen: false });
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages: numPages });
  }

  componentDidMount() {
    const userAgent = window.navigator.userAgent;
    const safariVersionMatch = userAgent.match(/Version\/(\d+\.\d+)/);
    if (safariVersionMatch) {
      const version = parseFloat(safariVersionMatch[1]);
      this.setState({ safariVersion: version });
    }
  }

  render() {
    const { element } = this.props;
    const fileUrl = `https://${this.props.cdnHost}/${element['s3-key']}`;
    const { safariVersion } = this.state;
    return !this.state.isPopupOpen ? (
      <div styleName="readdoc-container" onClick={this.openPopup}>
        <div className="story-element-file__title icon-ic_attachment">
          <div className="attachment-file">
            <span className="file-name">{element['file-name']}</span>
          </div>
        </div>
        <button styleName="link">View Document</button>
      </div>
    ) : (
      <>
        {safariVersion < 13 && !isMobile ? (
          <Modal showModal={true} close={this.closePopup}>
            <div styleName="pdfView">
              <span styleName="closebtn" onClick={this.closePopup}>
                X
              </span>
              <object
                data={`${fileUrl}#toolbar=0&navpanes=0&scrollbar=1`}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <div styleName="fallback-content">This browser does not support PDFs.</div>
              </object>
            </div>
          </Modal>
        ) : (
          <Modal showModal={true} close={this.closePopup} styleName="modal-wrapper">
            <div styleName="pdfPreview">
              <span styleName="closebtn" onClick={this.closePopup}>
                X
              </span>
              <div styleName="pdfPreviewMobile">
                <Document
                  file={fileUrl}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                  styleName="pdf-doc"
                >
                  <div styleName="pdf-page">
                    {Array.from(new Array(this.state.numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                  </div>
                </Document>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    cdnHost: 'media.assettype.com',
  };
}

AttachmentPreview.propTypes = {
  element: objectOf(any).isRequired,
  cdnHost: string.isRequired,
};

export default connect(mapStateToProps, {})(AttachmentPreview);
