import React from 'react';

import './contentnotavailable.module.css';

function ContentNotAvailable(props) {
  return (
    <>
      <div styleName="geotag-container">
        <div styleName="geotag-image-wrapper">
          <img styleName="geo-image" src="/images/geotag.png" alt="This Story is Unavailable" />
        </div>
        <div>
          <p styleName="geo-heading">
            This Story is <span styleName="geoheading-subtext">Unavailable</span> in your region
          </p>
        </div>
      </div>
    </>
  );
}
export default ContentNotAvailable;
