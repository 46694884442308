import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { connect, useDispatch, useSelector } from 'react-redux';
import './hard-paywall.m.css';
import Icon from '@mdi/react';
import Lottie from "lottie-light-react";
import LockIcon from './lock.json';
import { mdiArrowRight } from '@mdi/js';
import TitleWithDivider from '../../molecules/titles/with-divider';
import ListHeaderTitle from '../../atoms/list-header-title';
import {
  checkForSubscription,
  getPaymentOptionsAndPay,
  getSSOLoginUrl,
  getSSORedirectUrl,
  getSubscriptionParams,
  setUser,
  unsetUser
} from '../../../helpers/subscription-helper';
import { BQLink } from '../../atoms/bqlink';
import Paywallfeatures from './paywall-features';
import SubscribeModal from '../../pages/subscription/subscribe/subscribeModal';
import Loader from '../../atoms/loader';
import ShineButton from '../../atoms/shine-button';
import { PATH } from '../../../constants';
import { planSelection, signInClick, subscriptionInitiate } from '../../../helpers/datalayer-utils';
import { makeApiCall } from '../../../helpers/utils';

const HardPaywall = ({
  hardPayWallEnabled,
  softPayWallEnabled,
  features,
  isLoggedIn,
  userProfile,
  integrations,
  story
}) => {
  const dispatch = useDispatch();
  const skeletonArray = [1, 2, 3];
  const [isLogged, setLogged] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [isIndex, setIndex] = useState(1);
  const [amount, setAmount] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [loginUrl, setLoginUrl] = useState('');
  const isSubscriber = useSelector((state) => get(state, ['userData', 'isSubscriber'], null));
  const SSO_LOGIN_URL = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'subscription', 'login_url'], null),
  );
  const active_plans = features?.subscription?.active_plans_id;
  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));

  useEffect(() => {
    const url = new URL(window?.location?.href);
    getSSORedirectUrl(url).then((pageUrl) => {
      setRedirectUrl(pageUrl);
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const timer = setInterval(() => {
        const atToken = localStorage.getItem('UserAccesstypeJwtToken');
        if (!!atToken) {
          checkForSubscription(dispatch, atToken)
            .then(({ purchasedSubscription, isChurnUser }) => {
              if (!purchasedSubscription) {
                const accesstype = integrations.accesstype;
                const script = document.createElement('script');
                script.id = 'accesstype';
                script.src = `${accesstype.host}/frontend/v2/accesstype.js?key=${accesstype.accountId}${accesstype['env-sandbox'] && accesstype['env-sandbox'].enabled ? '&env=sandbox' : ''}`;
                script.async = true;
                script.defer = true;
                script.type = 'text/javascript';
                document.head.appendChild(script);
                return async () => {
                  await unsetUser(dispatch);
                  document.head.removeChild(script);
                };
              }
            });
          clearInterval(timer);
        }
      }, 1000);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    getSSOLoginUrl(
      integrations?.sso?.client_id,
      integrations?.sso?.client_secret,
      redirectUrl,
      SSO_LOGIN_URL,
    ).then((pageUrl) => {
      setLoginUrl(pageUrl);
    });
  }, [redirectUrl]);

  useEffect(() => {
    hardPayWallEnabled();
  }, [hardPayWallEnabled]);

  useEffect(() => {
    softPayWallEnabled();
  }, [softPayWallEnabled]);

  useEffect(() => {
    setLogged(isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isSubscriber) {
      makeApiCall('/subscription/get-subscription-plans').then((sp) => {
        if (sp.status) {
          let plans = sp?.data?.subscription_groups
            .map((group) => group?.subscription_plans).flat(1)
            .filter((plan) => plan.enabled === true && active_plans.includes(plan.id))
            .sort((a, b) => a.price_cents - b.price_cents);

          setPlans(plans);
          setSelectedPlan(plans[isIndex]);
          setAmount(plans[1]?.price_cents / 100);
          setLoading(false);

          const userSelectedPlan = JSON.parse(localStorage.getItem('USER_SELECTED_PLAN'));
          if (userSelectedPlan) {
            setSelectedPlan(userSelectedPlan);
            setAmount(userSelectedPlan?.price_cents / 100);
            plans.map((plan, index) => plan.id === userSelectedPlan.id && setIndex(index));
            const element = document.getElementById('hardpaywall');
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        }
      });
    }
  }, [isSubscriber]);

  const handlePlan = (plan, index) => {
    setIndex(index);
    setAmount(plan?.price_cents / 100);
    setSelectedPlan(plan);
  };

  const handlePayment = async () => {
    setPaymentLoader(true);
    setLoading(true);
    const userInfo = await userProfile;
    const params = await getSubscriptionParams(selectedPlan, userInfo);
    await setUser(userInfo);
    await getPaymentOptionsAndPay(params, 'razorpay', onPaymentSuccess, onPaymentFailure);
  };

  const onPaymentSuccess = async (subscription) => {
    await setActiveSubscription(subscription);
    await setOpen(true);
    await setPaymentLoader(false);
    setLoading(false);
  };

  const onPaymentFailure = () => {
    setLoading(false);
    setPaymentLoader(false);
  };

  const onSubscribe = async () => {
    await localStorage.setItem('USER_SELECTED_PLAN', JSON.stringify(selectedPlan));
    window.location = loginUrl;
  };
  const subBtnText = (amount, index) => {
    const price = [1499, 3299, 5499];
    let discountedAmnt = null;

    switch (index) {
      case 0:
        discountedAmnt = price[0] - amount;
        break;
      case 1:
        discountedAmnt = price[1] - amount;
        break;
      case 2:
        discountedAmnt = price[2] - amount;
        break;
      default:
        console.log('Invalid index');
    }

    return discountedAmnt;
  };

  let discountedAmnt = subBtnText(amount, isIndex);
  function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      if (typeof window !== 'undefined') {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 767);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
    }, []);

    return isMobile;
  }
  const isMobile = useIsMobile();

  return (
    <div styleName="hardpaywall-wrap">
      <img src="/images/white_gradient.png" styleName="white-shadow" />
      <div id="hardpaywall" styleName="story-subscribe-wrap">
        <img
          src={
            features?.independence_day?.active
              ? '/icons/hard-paywall-top-banner.svg'
              : '/images/story_top_img.svg'
          }
          styleName="top-banner"
        />
        <div styleName="heading-wrap">
          <div styleName="top-text">
            {!isLogged && (
              <div styleName="subscribe-txt">
                I’m already a Subscriber &nbsp;
                <div onClick={() => signInClick(userData, pageType, 'Sign In', 'paywall')}>
                  <BQLink href={loginUrl} styleName="signin">
                    Sign In
                  </BQLink>
                </div>
              </div>
            )}
            <div styleName="lock-icon">
              <Lottie
                animationData={LockIcon}
                loop={true}
                autoplay={true}
                styleName="custom-lock"
              />
            </div>
            <span styleName="title">{features.subscription.title || ''}</span>
            <div styleName="sub-title">
              Subscribe to Unlock & Enjoy your&nbsp;
              <span styleName="profit-plus">
                <br className="mobile-only" />
                Subscriber-Only
              </span>
              &nbsp;benefits
            </div>
          </div>
          {features?.independence_day?.active && (
            <div styleName="independence-pill">
              <img src={features.independence_day.premium_slider.flag} height="12px" width="auto" />
              <div styleName="flag-text">
                <img
                  src={
                    !isMobile
                      ? 'https://quintype-dropbox.s3-accelerate.amazonaws.com/bqprimehindi.quintype.com/2024-08-08/w0islgz5/FREEDOM_SALE_desk.png'
                      : 'https://quintype-dropbox.s3-accelerate.amazonaws.com/bqprimehindi.quintype.com/2024-08-08/g54xpg1x/FREEDOM_SALE.png'
                  }
                  alt="Sale"
                />
              </div>
              <div>Hurry Up! Limited Time Offer Only</div>
            </div>
          )}
        </div>
        <div styleName="subscription-wrap">
          <div styleName="title-wrapper">
            <TitleWithDivider>
              <ListHeaderTitle title="Choose a plan" />
            </TitleWithDivider>
            <div styleName="card-section">
              <div styleName="main-card">
                {!isLoading ? (
                  <>
                    {plans.map((list, index) => {
                      const text = list.description?.split(' ').slice(0, 2).join(' ');
                      let classNames = `card ${isIndex === index ? 'select-card' : ''}`;
                      if (isIndex === 1) {
                        if (index === 1) {
                          classNames += ' bg-svg-1';
                        } else if (index === 2) {
                          classNames += ' bg-svg-2';
                        }
                      } else if (isIndex === 2) {
                        if (index === 1) {
                          classNames += ' bg-svg-2';
                        } else if (index === 2) {
                          classNames += ' bg-svg-1';
                        }
                      } else {
                        if (index === 1 && features?.independence_day?.active) {
                          classNames += ' bg-svg-1';
                        } else if (index === 2 && features?.independence_day?.active) {
                          classNames += ' bg-svg-2';
                        }
                      }
                      return (
                        <div
                          styleName={classNames}
                          onClick={() => {
                            planSelection(userData, pageType, list.title, list.price_cents / 100);
                            handlePlan(list, index);
                          }}>
                          <div styleName="card-header">
                            {isIndex === index ? (
                              <img
                                src={
                                  features?.independence_day?.active
                                    ? '/icons/ic_radio_button_checked_org_24px.svg'
                                    : '/icons/ic_radio_button_checked_24px.svg'
                                }
                                height="23px"
                                width="23px"
                              />
                            ) : (
                              <img
                                src="/icons/ic_radio_button_unchecked_24px.svg"
                                height="23px"
                                width="23px"
                              />
                            )}
                            {index === 1 && <div styleName="label">POPULAR</div>}
                          </div>
                          <div styleName="card-footer">
                            <div
                              styleName={`duration ${isIndex === index ? 'select-duration' : ''}`}
                            >
                              {text.toLowerCase() === 'single month'
                                ? '1 Month'
                                : text.toLowerCase()}
                            </div>
                            <div styleName={`amount ${isIndex === index ? 'select-amt' : ''}`}>
                              ₹{list.price_cents / 100}
                              <span styleName="tag-amount">
                                {index === 0 ? '1,499' : index === 1 ? '3,299' : '5,499'}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {skeletonArray.map((list, index) => {
                      return (
                        <div styleName={`card`}>
                          <div styleName="card-header">
                            <img
                              src="/icons/ic_radio_button_unchecked_24px.svg"
                              height="23px"
                              width="23px"
                            />
                            {index === 1 && <div styleName="label-ss"></div>}
                          </div>
                          <div styleName="card-footer">
                            <div styleName="duration-skeleton"></div>
                            <div styleName="amount-skeleton"></div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              {isLoggedIn ? (
                <>
                  <div onClick={() => {
                    const planSelected = [...window.dataLayer]
                      .reverse()
                      .find((item) => item.event === 'plan_selection');

                    if (!planSelected && plans[1]) {
                      planSelection(userData, pageType, plans[1].title, plans[1].price_cents / 100);
                    } else if (!plans[1]) {
                      console.error("No second plan available!");
                    }

                    subscriptionInitiate(userData, 'story', story.headline, story.id, amount);
                    handlePayment();
                  }}>
                    <ShineButton
                      className={
                        features?.independence_day?.active
                          ? 'shine-button-independence'
                          : 'shine-button'
                      }
                      size="medium"
                      label={amount ? `Continue at ₹${amount}` : 'Continue'}
                      rounded="full"
                      discountedAmnt={discountedAmnt}
                    >
                      {paymentLoader ? (
                        <Loader
                          loaderSize="small"
                          style={{
                            border: '2px solid #ffffff',
                            borderTopColor: 'transparent',
                          }}
                        />
                      ) : (
                        <>
                          <div className="svg-wrapper" styleName="svg-wrapper">
                            <Icon path={mdiArrowRight} size={'20px'} />
                          </div>
                          {features?.independence_day?.active && (
                            <div className="discount-text">
                              You save ₹{discountedAmnt || ''} on this plan
                            </div>
                          )}
                        </>
                      )}
                    </ShineButton>
                  </div>
                  <div styleName="description">
                    {features?.independence_day?.active && !isMobile ? (
                      <img src={features?.independence_day?.paywall?.bottom_strip} />
                    ) : features?.independence_day?.active && isMobile ? (
                      <img src={features?.independence_day?.paywall?.bottom_strip_mb} />
                    ) : (
                      'Renews automatically. Cancel anytime.'
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div onClick={onSubscribe}>
                    <ShineButton
                      className="shine-button"
                      size="medium"
                      label="Subscribe"
                      rounded={features?.independence_day?.active ? 'default' : 'full'}
                      discountedAmnt={discountedAmnt}
                    >
                      {features?.independence_day?.active && (
                        <div className="discount-text">
                          You save ₹{discountedAmnt || ''} on this plan
                        </div>
                      )}
                    </ShineButton>
                  </div>
                  <div styleName="description">
                    {features?.independence_day?.active && !isMobile ? (
                      <img src={features?.independence_day?.paywall?.bottom_strip} />
                    ) : features?.independence_day?.active && isMobile ? (
                      <img src={features?.independence_day?.paywall?.bottom_strip_mb} />
                    ) : (
                      'Renews automatically. Cancel anytime.'
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Paywallfeatures />
        <div styleName="bottom-text">
          Still Not convinced ?&nbsp;
          <BQLink href={PATH.SUBSCRIPTION_PAGE} styleName="sub-link">
            Know More
          </BQLink>
        </div>
        <img src="/images/story_bottom_img.svg" styleName="bottom-banner" />
      </div>
      {isOpen && (
        <SubscribeModal isOpen={isOpen} setOpen={setOpen} subscription={activeSubscription} />
      )}
    </div>
  );
};
HardPaywall.propTypes = {};
HardPaywall.defaultProps = {};

const mapStateToProps = (state) => ({
  features: get(state, ['qt', 'config', 'features'], {}),
  integrations: get(state, ['qt', 'config', 'integrations'], {}),
  isLoggedIn: get(state, ['userData', 'isLoggedIn'], false),
  userProfile: get(state, ['userData', 'userProfile'], null),
});

const mapDispatchToProps = (dispatch) => ({
  hardPayWallEnabled: () => {
    dispatch({
      type: 'BQ_HARDPAYWALL_ENABLED',
    });
  },
  softPayWallEnabled: () => {
    dispatch({
      type: 'NP_REGISTRATIONPAYWALL_ENABLED',
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(HardPaywall);
