import React from 'react';
import { objectOf, any, string } from 'prop-types';
import Lightbox from 'react-images';
import { connect } from 'react-redux';

import theme from './theme';
import ImageShare from './share/share';
import StoryImageViewer from '../../../molecules/story-image-viewer';

import './image.module.css';

function generatePath(imageUrl, size) {
  return imageUrl.replace(/\/-?\d+x-?\d+\./g, `/${size}x-1.`);
}

function generateExternalImageUrls(imageUrl = '', defaultSize, sizes = []) {
  return {
    externalImageSrc: generatePath(imageUrl, defaultSize),
    externalImageSrcset: sizes.map((size) => `${generatePath(imageUrl, size)} ${size}w`).join(','),
  };
}

class LightBoxImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
    };
    this.openLightbox = this.openLightbox.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.generateImage = this.generateImage.bind(this);
  }

  openLightbox() {
    this.setState({ lightboxIsOpen: true });
  }

  closeLightbox() {
    this.setState({ lightboxIsOpen: false });
  }

  generateImage(type, element, caption, externalImageSrc, externalImageSrcset) {
    let imgClassName, imgStyleName;
    switch (type) {
      case 'gallery':
        imgClassName = {
          responsiveImage: 'story-element-image-gallery__image',
        };
        imgStyleName = {
          figure: 'container',
          figcaption: 'title',
        };
        return (
          <StoryImageViewer
            imgClassName={imgClassName}
            imgStyleName={imgStyleName}
            aspectRatio={[1, 1]}
            type={type}
            openLightbox={this.openLightbox}
            element={element}
            caption={caption}
          />
        );
      case 'slideshow':
        imgClassName = {
          figure: 'story-element-image-slideshow__image-container',
          responsiveImage: 'story-element-image-slideshow__image',
          figcaption: 'story-element-image-slideshow__caption',
        };
        return (
          <StoryImageViewer
            imgClassName={imgClassName}
            imgStyleName={imgStyleName}
            aspectRatio={[1, 1]}
            type={type}
            openLightbox={this.openLightbox}
            element={element}
            caption={caption}
          />
        );
      case null:
        imgClassName = {
          img: 'qt-image',
          figcaption: 'story-element-image-title',
        };
        imgStyleName = {
          figure: 'wrapper',
          figcaption: 'title',
          span: 'expand-icon',
        };
        return (
          <StoryImageViewer
            imgClassName={imgClassName}
            imgStyleName={imgStyleName}
            aspectRatio={[1, 1]}
            type={type}
            openLightbox={this.openLightbox}
            element={element}
            caption={caption}
            externalImageSrc={externalImageSrc}
            externalImageSrcset={externalImageSrcset}
          />
        );
    }
  }

  render() {
    const { element, story, card, type, parentElement } = this.props;
    const caption = element['image-attribution'] || element.title || story.headline;
    const captionText = caption.replace(/<[^>]+>/g, '');
    const { externalImageSrc, externalImageSrcset } = generateExternalImageUrls(
      element.url,
      560,
      [280, 560, 760, 960, 1200],
    );
    return (
      <React.Fragment>
        {this.generateImage(type, element, caption, externalImageSrc, externalImageSrcset)}
        {this.state.lightboxIsOpen && (
          <Lightbox
            images={[
              {
                src: externalImageSrc || `//${this.props.cdnHost}/${element['image-s3-key']}`,
                caption: captionText,
                srcset: externalImageSrc
                  ? externalImageSrcset
                  : `//${this.props.cdnHost}/${element['image-s3-key']}`,
              },
            ]}
            isOpen={this.state.lightboxIsOpen}
            onClose={this.closeLightbox}
            showImageCount={false}
            width={1400}
            theme={theme}
            customControls={[
              <ImageShare
                key={1}
                story={story}
                element={element}
                parentElement={parentElement}
                card={card}
                publisherUrl={this.props.publisherUrl}
              />,
            ]}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cdnHost: state.qt.config['cdn-image'],
    publisherUrl: state.qt.config['sketches-host'],
  };
}

LightBoxImage.defaultProps = {
  card: null,
  type: null,
  parentElement: null,
};

LightBoxImage.propTypes = {
  element: objectOf(any).isRequired,
  parentElement: objectOf(any),
  card: objectOf(any),
  story: objectOf(any).isRequired,
  cdnHost: string.isRequired,
  publisherUrl: string.isRequired,
  type: string,
};

export default connect(mapStateToProps, {})(LightBoxImage);
