import React from 'react';
import { connect } from 'react-redux';

import SingleVideoStoryWithPlayLists from './single-video-with-playlists';
import get from 'lodash/get';
import { GoogleOAuthProvider } from '@react-oauth/google';

const VideoStory = (props) => {
  return (
    <GoogleOAuthProvider clientId={get(props?.oauth, ['client-id'], '')}>
      <SingleVideoStoryWithPlayLists {...props} testPage={props.test_page} />
    </GoogleOAuthProvider>
  );
};

const mapStateToProps = (state) => ({
  oauth: get(state, ['qt', 'config', 'integrations', 'google-oauth'], ''),
});

export default connect(mapStateToProps)(VideoStory);
