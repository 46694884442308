import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import './image-gallery.module.css';
import LightBoxImage from '../image/image';

const ImageGallery = props => {
  const { element, story, card } = props;
  const galleryList = map(element['story-elements'], imageElement => (
    <li className="story-element-image-gallery__item" key={imageElement.id}>
      <LightBoxImage
        element={imageElement}
        parentElement={element}
        story={story}
        card={card}
        type="gallery"
      />
    </li>
  ));
  return (
    <div>
      <div styleName="header">Images</div>
      <ul styleName="imageGalleryWrapper">{galleryList}</ul>
    </div>
  );
};

ImageGallery.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  story: PropTypes.objectOf(PropTypes.any).isRequired,
  card: PropTypes.objectOf(PropTypes.any)
};

ImageGallery.defaultProps = {
  card: null
};

export default ImageGallery;
