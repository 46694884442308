import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import aes from 'crypto-js/aes';
import utf8 from 'crypto-js/enc-utf8';
import { isPrivateMode } from '../helpers/utils';

const METERED_STORY_ACCESS_LEVEL = 100;
const PAID_STORY_ACCESS_LEVEL = 999;
const REGISTRATION_PAYWALL_LEVEL = 'login';

const WithAccess = ({ storyAccess, storyAccessLevel, isSubscriber, children }) => {
  const [state, setState] = useState({
    hasAccess: storyAccessLevel !== PAID_STORY_ACCESS_LEVEL,
    access: storyAccess === REGISTRATION_PAYWALL_LEVEL
  });

  useEffect(() => {
    if (storyAccessLevel !== PAID_STORY_ACCESS_LEVEL) {
      const hasDirectAccess = storyAccessLevel !== PAID_STORY_ACCESS_LEVEL || isSubscriber;

      setState((prevState) => ({
        ...prevState,
        hasAccess: hasDirectAccess,
      }));
    }
  }, [storyAccessLevel, isSubscriber]);

  const { hasAccess, access } = state;

  return children({
    hasAccess,
    access,
  });
};

WithAccess.propTypes = {
  storyId: PropTypes.string.isRequired,
  storyAccess: PropTypes.string,
  storyAccessLevel: PropTypes.any,
  isSubscriber: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

WithAccess.defaultProps = {
  storyAccess: 'public',
  storyAccessLevel: null,
};

export default WithAccess;
