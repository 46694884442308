import React from 'react';
import PropTypes from 'prop-types';
import SocialShareBase from '../../../../atoms/social-share-list/social-share-base';
import './share.module.css';

function Template({ services, socialShareConfig }) {
  return (
    <ul styleName="socialList">
      {services.map(service => (
        <li key={service}>
          <a
            target="_blank"
            styleName="listLink"
            className={`${socialShareConfig[service].className}`}
            data-social-media-type={service}
            href={socialShareConfig[service].url}
            rel="noopener noreferrer nofollow"
            data-social-media-share-url={socialShareConfig[service].url}
            title={socialShareConfig[service].title}
            onClick={socialShareConfig[service].func}
            data-component-type="share"
            data-track-click={service}
          />
        </li>
      ))}
    </ul>
  );
}

Template.propTypes = {
  services: PropTypes.arrayOf(PropTypes.any).isRequired,
  socialShareConfig: PropTypes.objectOf(PropTypes.any).isRequired
};

const ImageShare = ({ story, card, element, publisherUrl, parentElement }) => (
  <div styleName="container" data-testid="imgshare">
    <SocialShareBase
      publisherUrl={publisherUrl}
      url={story.slug}
      title={story.headline}
      template={Template}
      story={story}
      card={card}
      element={element}
      parentElement={parentElement}
      services={['whatsapp', 'twitter', 'facebook', 'linkedin', 'telegram', 'copy']}
      encode={true}
      classStyle={true}
    />
  </div>
);

ImageShare.propTypes = {
  story: PropTypes.objectOf(PropTypes.any).isRequired,
  publisherUrl: PropTypes.string.isRequired,
  element: PropTypes.objectOf(PropTypes.any),
  parentElement: PropTypes.objectOf(PropTypes.any),
  card: PropTypes.objectOf(PropTypes.any)
};

ImageShare.defaultProps = {
  element: null,
  card: null,
  parentElement: null
};

export default ImageShare;
