import React from 'react';
import { objectOf, any } from 'prop-types';
import queryString from 'query-string';
import orderBy from 'lodash/orderBy';
import { isAfter } from 'date-fns';

import StoryBaseTemplate from './story-base-template';
import StoryCard from '../story/story-card/story-card';
import NewUpates from '../story/new-updates/new-updates';

import './live-blog.m.css';
import SortMenu from '../atoms/sort-menu/sort-menu';

class LiveBlog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      story: props.story,
      order: 'desc',
      newUpdates: 0,
      sharedCardId: '',
      recentlyAddedCardId: ''
    };
    this.orderChanged = this.orderChanged.bind(this);
    this.removeUpdates = this.removeUpdates.bind(this);
  }

  componentDidMount() {
    this.storyRefreshInterval = setInterval(this.updateStory.bind(this), 5000);
    const queryObject = queryString.parse(window.location.search);
    this.setSharedCardId(queryObject.cardId);
    if (queryObject.cardId) {
      const sharedCardElement = document.getElementById(`card-${queryObject.cardId}`);
      setTimeout(() => sharedCardElement && sharedCardElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start'}), 1000);
    }
  }

  componentWillUnmount() {
    if (this.storyRefreshInterval) clearInterval(this.storyRefreshInterval);
  }

  setSharedCardId(cardId) {
    this.setState({ sharedCardId: cardId });
  }

  unsetSharedCardId() {
    this.setState({ sharedCardId: '' });
  }

  updateStory() {
    return fetch(`/api/v1/stories/${this.props.story.id}`)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.status);
        }
      })
      .then(({ story }) => {
        const newUpdates = this.findNewUpdates(story, this.state.story);
        this.setState({ story, newUpdates });
        const firstCard = story.cards[0];
        const secondCard = story.cards[1];

        const firstCardAddedTime = new Date(firstCard['card-added-at']);
        const secondCardAddedTime = new Date(secondCard['card-added-at']);
        const recentlyAddedCardId = isAfter(firstCardAddedTime, secondCardAddedTime)
          ? firstCard.id
          : secondCard.id;
        this.setState({ recentlyAddedCardId: recentlyAddedCardId });
      })
      .catch(error => console.error('Error:', error));
  }

  findNewUpdates(newStory = {}, currentStory = {}) {
    const newStoryCards = newStory.cards ? newStory.cards.length : 0;
    const currentStoryCards = currentStory.cards ? currentStory.cards.length : 0;
    const cardsAdded = newStoryCards - currentStoryCards;
    return this.state.newUpdates + cardsAdded;
  }

  removeUpdates() {
    this.setState({ order: 'desc', newUpdates: 0 });
  }

  orderChanged(order) {
    this.setState({ order });
  }

  storyContent(story) {
    return <React.Fragment>{this.renderCards(story)}</React.Fragment>;
  }

  extractPinnedCard() {
    const pinnedCard = Array.prototype.filter.call(
      this.state.story.cards,
      card => card.metadata && card.metadata.attributes && card.metadata.attributes['is-pinned?']
    );
    const filteredCards = Array.prototype.filter.call(
      this.state.story.cards,
      card => !card.metadata || !card.metadata.attributes || !card.metadata.attributes['is-pinned?']
    );
    return { pinnedCard, filteredCards };
  }

  renderCards(story) {
    const { pinnedCard, filteredCards } = this.extractPinnedCard();
    const sortedCards = orderBy(
      filteredCards,
      [
        function(card) {
          return card['card-added-at'];
        }
      ],
      [this.state.order]
    );

    const expandedCards = 5;

    return (
      <React.Fragment>
        <div id={`story-${story.id}-first-card`} />
        <div styleName="live-feed-with-sort">
          <div styleName="live-feed-title">
            <span className="icon-ic_broadcast"></span> LIVE FEED
          </div>
          {this.state.order && (
            <SortMenu active={this.state.order} orderChanged={this.orderChanged} />
          )}
        </div>

        {pinnedCard.length !== 0 && (
          <StoryCard
            key={pinnedCard[0].id}
            card={pinnedCard[0]}
            story={story}
            cardIndex={-1}
            expandedCards={1}
            order={this.state.order}
            pinned
            unsetSharedCardId={this.unsetSharedCardId.bind(this)}
          />
        )}

        {sortedCards.map((card, index) => (
          <React.Fragment key={card.id}>
            <StoryCard
              key={card.id}
              card={card}
              story={story}
              cardIndex={index}
              expandedCards={expandedCards}
              order={this.state.order}
              sharedCardId={this.state.sharedCardId}
              unsetSharedCardId={this.unsetSharedCardId.bind(this)}
            />
          </React.Fragment>
        ))}

        <div id={`story-${story.id}-last-card`} />
      </React.Fragment>
    );
  }

  render() {
    const { story, order, newUpdates } = this.state;
    const cardId = `card-${this.state.recentlyAddedCardId}`;

    return (
      <React.Fragment>
        {this.state.newUpdates > 0 && (
          <NewUpates cardId={cardId} newUpdates={newUpdates} removeUpdates={this.removeUpdates} />
        )}
        <StoryBaseTemplate
          story={story}
          content={this.storyContent(story)}
          cardOrder={order}
          orderChanged={this.orderChanged}
        />
      </React.Fragment>
    );
  }
}

LiveBlog.propTypes = {
  story: objectOf(any).isRequired
};

export default LiveBlog;
