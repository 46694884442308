import wretch from 'wretch';

export function addEntityToFirebase(type, payload, memberId, entities) {
  return wretch('/entity/add')
    .headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
    .post({ type, payload, memberId, entities })
    .res();
}

function deleteStocks(payload, memberId, memberData) {
  return wretch('/stocks/remove')
    .headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
    .post({ stocks: payload.stocksList, memberId, memberData })
    .res();
}

function updateAlert(payload, memberId) {
  return wretch('/stocks-alerts/set')
    .headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
    .post({ alerts: payload.alerts, memberId })
    .res();
}
function removeAlert(payload, memberId, alerts) {
  return wretch('/stocks-alerts/remove')
    .headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
    .post({ alert: payload.alert, memberId, alerts })
    .res();
}

function updateAlertMedium(payload, memberId) {
  const alertMediums = Object.keys(payload.alertMediums).filter(
    medium => payload.alertMediums[medium]
  );
  return wretch('/stocks-alert-medium/update')
    .headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
    .post({ alertMediums: alertMediums, memberId })
    .res();
}

function setAlarm(payload, memberId) {
  return wretch('/stocks/alarms/set')
    .headers({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    })
    .post({ stocks: payload, memberId })
    .res();
}
