import React, { useEffect, useState } from 'react';
import { objectOf, string, any, bool } from 'prop-types';
import get from 'lodash/get';
import { connect, useSelector } from 'react-redux';
import SubscriptionComboBox from '../subscription-combo-box';
import { getPrimarySection, getSourceDisplayName } from '../../../helpers/story-helpers';
import { watchLiveInteraction } from '../../../helpers/datalayer-utils';
import AboutAuthor from '../authors/aboutAuthor';
import { WithLazy } from '@quintype/components';

import './footer.module.css';
import { getUserStatus } from '../../../helpers/utils';

const SyndicatedSource = ({ story }) => {
  const author = get(story, 'author-name');
  if (author === 'PTI') return null;

  const syndicatedFrom = get(story, ['metadata', 'story-attributes', 'syndicatedfrom', '0']);
  return <div styleName="syndicated-source">{getSourceDisplayName(syndicatedFrom)}</div>;
};

SyndicatedSource.propTypes = {
  story: objectOf(any).isRequired,
};

const Footer = (props) => {
  const userData = useSelector((state) => get(state, ['userData']));
  const authorDetails = props.story.authors ?? [];
  const datalayerFields = {
    page_template: 'story',
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: getUserStatus(),
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
  };

  function checkBio(author) {
    return author.bio;
  }
  const isLiveBlog = get(props, ['story', 'story-template']) === 'live-blog';

  return (
    <React.Fragment>
      {props.story['story-template'] === 'news-elsewhere' && (
        <a href={props.story.metadata['reference-url']} styleName="read-more">
          Read More
        </a>
      )}
      <SectionLink
        section={getPrimarySection(props.story.sections)}
        storyHeadline={props.story.headline}
        isGeoBlock={props.isGeoBlock}
        isLiveBlog={isLiveBlog}
        isPremiumTemplate={props?.isPremiumTemplate}
        isSubscriber={props.isSubscriber}
      />

      <WithLazy>
        {() => (
          <>
            {authorDetails && authorDetails.some(checkBio) && (
              <h4 styleName="about-the-author">WRITTEN BY</h4>
            )}
            {authorDetails &&
              authorDetails.map((author, i) => {
                return <AboutAuthor authorDetails={author} key={i} />;
              })}
          </>
        )}
      </WithLazy>

      <WithLazy>
        {() => (
          <SubscriptionComboBox
            source="footer-Story Page"
            variant="horizontal"
            datalayerFields={datalayerFields}
          />
        )}
      </WithLazy>
      <div
        className="content-center ad-bottom-margin mobile-only"
        styleName="belowrecommendedadunit"
      ></div>
    </React.Fragment>
  );
};

const SectionLink = ({ isGeoBlock }) => {
  const userData = useSelector((state) => get(state, ['userData']));
  const content = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'story_footer_text'], ''),
  );
  useEffect(() => {
    const liveTvLink = document.getElementById('live-tv-link');
    if (liveTvLink) {
      liveTvLink.addEventListener('click', () =>
        watchLiveInteraction(userData, 'story', 'story-footer'),
      );
    }
    return () => {
      if (liveTvLink) {
        liveTvLink.removeEventListener('click', () =>
          watchLiveInteraction(userData, 'story', 'story-footer'),
        );
      }
    };
  }, [userData]);
  return (
    <div styleName="authoritative-section" className="paywall">
      {!isGeoBlock && <div styleName="static-text" dangerouslySetInnerHTML={{ __html: content }} />}
    </div>
  );
};

SectionLink.propTypes = {
  isGeoBlock: bool,
};

Footer.propTypes = {
  story: objectOf(any).isRequired,
  isGeoBlock: bool,
  isPremiumTemplate: bool,
  isSubscriber: bool,
};

const mapStateToProps = (state) => {
  return {
    isSubscriber: get(state, ['userData', 'isSubscriber'], false),
  };
};
export default connect(mapStateToProps, () => ({}))(Footer);
