import React from 'react';
import './paywall-features.m.css';
import { SUBSCRIBER_FEATURES, SUBSCRIBER_FEATURES_MOB } from '../../../constants';

const PaywallFeatures = () => {
  const PaywallList = (list) => (
    <div styleName="icon-title">
      <img
        src={list.link2}
        styleName="sub-icon"
        alt={list.title}
        height={list.height}
        width={list.width}
      />
      <div styleName="text-wrap">
        <div styleName="sub-title">{list.title}</div>
        {list.desc === 'Bloomberg' ? (
          <div styleName="sub-desc bloomberg">
            <img src="/icons/bloomberg.svg" alt={list.desc} styleName="bloomberg-icon" /> Stories
          </div>
        ) : (
          <div styleName="sub-desc">{list.desc}</div>
        )}
      </div>
      <img
        src="/icons/rhoumbus.svg"
        styleName="rhoumbus-icon"
        height="14px"
        width="14px"
        className="mobile-only"
      />
    </div>
  );

  return (
    <div styleName="sub-footer">
      <div styleName="heading-wrap">
        <img
          src="/icons/left-divider-icon-small.svg"
          styleName="divider-icon"
          className="mobile-only"
        />
        <img src="/icons/left-divider-icon.svg" styleName="divider-icon" className="desktop-only" />
        <span styleName="heading">As a Subscriber you get</span>
        <img
          src="/icons/right-divider-icon-small.svg"
          styleName="divider-icon"
          className="mobile-only"
        />
        <img
          src="/icons/right-divider-icon.svg"
          styleName="divider-icon"
          className="desktop-only"
        />
      </div>
      <div styleName="subscriber-get" className="mobile-only">
        {SUBSCRIBER_FEATURES_MOB.map((list) => (
          <PaywallList key={list.title} {...list} />
        ))}
      </div>

      <div styleName="subscriber-get" className="desktop-only">
        {SUBSCRIBER_FEATURES.map((list) => (
          <PaywallList key={list.title} {...list} />
        ))}
      </div>
    </div>
  );
};

export default PaywallFeatures;
