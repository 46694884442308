import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import './latest-news-widget.m.css';
import CardImage from '../../atoms/cardimage';
import { isStoryExclusive } from '../../../helpers/story-helpers';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { contentClick } from '../../../helpers/datalayer-utils';
import LatestNews from '../../../json/story-test-page/latestNews.json';

const LatestNewsWidget = (props) => {
  const test_page = props?.story?.test_page;
  const [allStories, setAllStories] = useState([]);
  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));
  useEffect(() => {
    if (!test_page) {
      (async () => {
        const response = await (
          await fetch(
            `/api/v1/search?sort=latest-published&limit=5&fields=headline,subheadline,published-at,summary,hero-image-s3-key,hero-image-caption,hero-image-metadata,author-name,author-id,slug,url,id,published-at,updated-at,alternative,access-level-value`,
          )
        ).json();
        if (isMobile) {
          setAllStories(response?.['results']?.['stories']?.slice(1));
        } else {
          setAllStories(response?.['results']?.['stories']);
        }
      })();
    } else {
      if (isMobile) {
        setAllStories(LatestNews.slice(1));
      } else {
        setAllStories(LatestNews);
      }
    }
  }, []);

  return (
    <React.Fragment>
      {allStories?.length > 0 && (
        <div styleName="latest-wrapper">
          <div styleName="heading-wrapper">
            <h4 styleName="heading">
              <img
                width="20px"
                height="20px"
                alt="dice"
                styleName="dice-img"
                src="https://media.assettype.com/bloombergquint/2022-05/6cf7dbaf-011a-4040-9825-744af7d9e97f/ic_dice.png"
              />
              LATEST NEWS
            </h4>
          </div>
          <div styleName="section-wrapper">
            <div styleName="story">
              {allStories.map((story, index) => (
                <a
                  href={`/${story?.slug}`}
                  key={index}
                  styleName="item"
                  onClick={() => contentClick(userData, story, pageType)}
                >
                  <div styleName="thumbnail">
                    <CardImage
                      story={story}
                      aspectRatio={isMobile ? [16, 9] : [1, 1]}
                      imageType={isMobile ? 'image16x9' : 'image1x1'}
                      showHeroDetails={false}
                      isLazy={true}
                      isExclusive={isStoryExclusive(story)}
                    />
                  </div>
                  <div styleName="text">
                    <h5 styleName="text-heading">{story?.headline}</h5>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LatestNewsWidget;
