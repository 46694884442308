import React from 'react';
import { number } from 'prop-types';

import { getStoryDate } from '../../../helpers/utils';

import './published-info.module.css';
import SvgIcon from '../../atoms/svg-icon';

const PublishedInfo = ({ updatedAt, publishedAt }) => (
  <div styleName="published-info">
    <div>
      <SvgIcon type="feather-without-circle" source="image" />
      <time
        dateTime={new Date(publishedAt).toISOString().replace('Z', '+05:30')}
        styleName="published-on"
      >
        <div>{getStoryDate(publishedAt)}</div>
      </time>
    </div>

    <div>
      <SvgIcon type="timer" source="image" />
      <time
        dateTime={new Date(updatedAt).toISOString().replace('Z', '+05:30')}
        styleName="updated-on"
      >
        <div>{getStoryDate(updatedAt)}</div>
      </time>
    </div>
  </div>
);

PublishedInfo.propTypes = {
  updatedAt: number.isRequired,
  publishedAt: number.isRequired
};

export default PublishedInfo;
