import React from 'react';
import { StoryElement } from '@quintype/components';

import StorySnapshot from './story-snapshot/story-snapshot';
import EditorsTakeReadMore from './editors-take/editors-take';
import { BrightCove } from './brightcove';
import ImageGallery from './image-gallery/image-gallery';
import ImageSlideshow from './image-slideshow/image-slideshow';
import Image from './image/image';
import JSEmbed from './jsembed/jsembed';
import AlsoRead from './also-read';
import AlsoReadMultiLinks from './also-read-multi-links';
import AttachmentPreview from './attachment-preview/attachment-preview';
import { skipTextElement, syndicatedSource } from '../../../helpers/utils';

import './story-elements.m.css';

const getCustomTemplates = (element, cardAttributeTemplate) => {
  if (element.type === 'image') return { image: Image };
  if (element.type === 'external-file' && element['file-type'] === 'image')
    return { 'external-file': Image };
  if (element.type === 'file' && element['content-type'] === 'application/pdf')
    return { file: AttachmentPreview };
  if (element.type === 'jsembed') {
    return { jsembed: JSEmbed };
  }
  if (element.subtype === 'brightcove-video') return { 'brightcove-video': BrightCove };

  if (element.subtype === 'image-gallery')
    return {
      'image-gallery': element.metadata.type === 'gallery' ? ImageGallery : ImageSlideshow,
    };

  if (element.subtype === 'also-read') {
    if (cardAttributeTemplate === 'editors-take') return { 'also-read': EditorsTakeReadMore };
    if (cardAttributeTemplate === 'story-snapshot') return { 'also-read': StorySnapshot };
    return { 'also-read': AlsoRead };
  }

  if (element.subtype === 'cta') {
    return { 'cta': AlsoReadMultiLinks }
  }

  return {};
};

const storyElementWithCustomTemplates = (card, element, story, cardAttributeTemplate) => {
  let validStoryElement = true;

  if (syndicatedSource(story) === 'Bqlive') {
    validStoryElement = skipTextElement(
      element,
      /--With assistance|To contact the reporter|To contact the editor|With assistance|To contact Bloomberg/
    );
  }

  if (element.type === 'image' || element.type === 'jsembed' || element.type === 'youtube-video') {
    return (
      <React.Fragment>
        <StoryElement
          card={card}
          element={element}
          key={element.id}
          story={story}
          loadIframeOnClick={false}
          templates={getCustomTemplates(element, cardAttributeTemplate)}
          style={{ margin: '50px' }}
        />
      </React.Fragment>
    );
  }
  if (element.type === 'text') {
    const strdata = JSON.stringify(story.cards);
    const updatedStory = strdata
      .replace('www.bloombergquint', 'www.ndtvprofit')
      .replace('www.bqprime', 'www.ndtvprofit');

    story.cards = JSON.parse(updatedStory);
    return createStoryElement(
      card,
      element,
      element.id,
      story,
      getCustomTemplates(element, cardAttributeTemplate)
    );
  }
  if (validStoryElement) {
    return createStoryElement(
      card,
      element,
      element.id,
      story,
      getCustomTemplates(element, cardAttributeTemplate)
    );
  }
};

const createStoryElement = (card, element, key, story, templates) => {
  return (
    <div styleName="story-element-ctx">
      {React.createElement(StoryElement, {
        card,
        element,
        key,
        story,
        templates,
      })}
    </div>
  );
};

export default storyElementWithCustomTemplates;
