import React from 'react';
import { arrayOf, any, objectOf, func, string, number } from 'prop-types';
import { connect } from 'react-redux';
import { Scrollchor } from 'react-scrollchor';
import get from 'lodash/get';
import { titleText } from '../../../helpers/utils';
import { CLICK_KEY_HIGHLIGHT } from '../../../../store/actions';
import './key-highlight.module.css';

const KeyHighlights = ({ cards, limit = 5, lbhref = null }) => {
  const keyhighlights =
    cards
      ?.filter(card => get(card, ['metadata', 'attributes', 'key-event'], false))
      .map(card => ({ title: titleText(card), card })) || [];

  if (keyhighlights.length === 0) {
    return <div className="no-key-highlights" />;
  }

  return (
    <React.Fragment>
      <div styleName="wrapper" className="key-highlights-wrapper">
        <div styleName="heading-wrapper">
          <h2 styleName="heading">
            <span>KEY&nbsp;</span>HIGHLIGHTS
          </h2>
        </div>
        <ul styleName="list">
          {keyhighlights.slice(0, limit).map(highlight => (
            <Keyhighlight
              key={`highlight-${highlight.card.id}`}
              highlight={highlight}
              lburl={lbhref}
            />
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

const KeyHighlightBase = ({ highlight, lburl, onClick }) => {
  const onKeyItem = () => {
    window.location.href = `${window.location.origin}/${lburl}#card-${highlight.card.id}`;
  };
  return (
    <li id={highlight.card.id} styleName="key" onClick={onClick}>
      {lburl ? (
        <a
          href={`${lburl}#card-${highlight.card.id}`}
          id={highlight.card.id}
          styleName="key-link"
          onClick={onKeyItem}
        >
          <div styleName="watch" />
          <h3 id={highlight.card.id} styleName="text">
            {highlight.title}
          </h3>
        </a>
      ) : (
        <Scrollchor
          to={`#card-${highlight.card.id}`}
          styleName="key-link"
          animate={{ offset: -90 }}
        >
          <div styleName="watch" />
          <h3 id={highlight.card.id} styleName="text">
            {highlight.title}
          </h3>
        </Scrollchor>
      )}
    </li>
  );
};

KeyHighlights.propTypes = {
  cards: arrayOf(any).isRequired,
  label: string,
  limit: number,
  lbhref: string
};

KeyHighlightBase.propTypes = {
  highlight: objectOf(any).isRequired,
  onClick: func.isRequired,
  lburl: string
};

function mapDispatchToProps(dispatch) {
  return {
    onClick: e => {
      e.preventDefault();
      e.stopPropagation();
      dispatch({
        type: CLICK_KEY_HIGHLIGHT,
        cardId: e.target.id
      });
    }
  };
}

const Keyhighlight = connect(state => ({}), mapDispatchToProps)(KeyHighlightBase);

export { KeyHighlights };
