import React from 'react';
import get from 'lodash/get';
import './aboutAuthor.m.css';
import { object } from 'prop-types';
import { isMobile } from 'react-device-detect';

const AboutAuthor = ({
  authorDetails,
  showSocials = true,
  desktopCharLimit = 55,
  truncateBio = true
}) => {
  const heading = authorDetails?.name ?? 'No stories for this author';
  const avatar = get(authorDetails, ['avatar-url']) ?? heading?.slice(0, 1);
  const bio = authorDetails?.bio;
  const halfbio = truncateBio
    ? bio?.substring(0, isMobile ? 75 : desktopCharLimit).concat('...')
    : bio;
  const name = heading?.split(' ');
  const [firstname, ...lastname] = name ?? [];
  let socials;
  let socialNames;
  let socialUrl;
  const validSocials = ['twitter', 'instagram', 'facebook', 'linkedin'];

  if (showSocials) {
    socials = authorDetails?.social ?? {};
    socialNames = Object.keys(socials);
  }

  return (
    <>
      {bio && (
        <div styleName="author-heading">
          {avatar.length < 2 ? (
            <div styleName="author-name">{heading.slice(0, 1)}</div>
          ) : (
            <img data-src={avatar} alt="author-name" loading="lazy" width="60" />
          )}
          <div styleName="author-details">
            <div styleName="author-title-social">
              <a
                href={`/author/${authorDetails.id}/${authorDetails.slug}?src=auth_stry`}
                target="_blank"
                rel="noreferrer"
              >
                <span styleName="author-name_first">{firstname}&nbsp;</span>
                <span styleName="author-name_last">{` ${lastname.join(' ')}`}</span>
              </a>
            </div>
            <div styleName="author-bio">
              <h5>
                {halfbio}
                {truncateBio ? (
                  <span style={{ color: 'red', cursor: 'pointer' }}>
                    <a
                      href={`/author/${authorDetails.id}/${authorDetails.slug}?src=auth_stry`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      more
                    </a>
                  </span>
                ) : null}
              </h5>
            </div>
          </div>
          {showSocials ? (
            <div styleName="social">
              {socials &&
                Object.values(socials).map((social, i) => {
                  socialUrl =
                  social?.url && social?.handle ? `https://www.${socialNames[i]}.com/${social?.handle}` : social?.url ? `https://www.${socialNames[i]}.com/${social?.url}` : '#';
                  return (
                    <a key={i} href={socialUrl} target="_blank" rel="noreferrer">
                      <span
                        styleName={
                          validSocials.includes(socialNames[i])
                            ? `${socialNames[i]}-icon icon`
                            : 'icon'
                        }
                        className={`icon-ic_${socialNames[i]}`}
                      ></span>
                    </a>
                  );
                })}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

AboutAuthor.propTypes = {
  authorDetails: object
};

export default AboutAuthor;
