import React from 'react';
import { StoryPage } from './story';

class StoryPagePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: true
    };
  }

  componentDidMount() {
    this.collectStoryData();
  }

  collectStoryData() {
    global.addEventListener('message', event => {
      if (event.data.story) {
        this.setState({
          story: event.data.story,
          mostRead: {
            items: Array(5).fill({
              type: 'story',
              story: event.data.story
            })
          }
        });
      }
    });
  }

  render() {
    if (!this.state.story) return <div />;
    return <StoryPage data={this.state} />;
  }
}

export { StoryPagePreview };
