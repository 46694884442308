/* eslint-disable max-len */
import React, { useState } from 'react';
import { func, object, string, bool, arrayOf, objectOf, any } from 'prop-types';
import { connect } from 'react-redux';
import size from 'lodash/size';
import get from 'lodash/get';
import Notification from '../../atoms/stock-notifications';
import { slugify } from '../../../helpers/utils';
import { addEntityToFirebase } from '../../../../isomorphic/stockslist-api';
import {
  BQ_STOCK_NOTIFICATIONS_DISABLE,
  BQ_STOCK_NOTIFICATIONS_SET,
  SHOW_LOADER,
  REMOVE_LOADER,
  BQ_STOCK_WATCHLIST_GET,
  BQ_STOCK_ALREADY_ADDED,
  BQ_ENTITY_WATCHLIST_ADD_FROM_STORY,
  BQ_NOTIFICATION_LIST,
} from '../../../../store/actions';
import './market-box.module.css';
import SvgIcon from '../../atoms/svg-icon';

const getAllDirClassName = (val, upClass = '', downClass = '', defaultClass = '') => {
  if (val > 0) return upClass;
  if (val < 0) return downClass;
  return defaultClass;
};

const Tile = ({ item, checkUserLoggedIn, stockNotification, stockStatus, failure, loggedIn }) => {
  const stockName = item.type !== 'stock' ? item.name : item?.nseCode && item?.nseCode?.toUpperCase() !== "NULL"? item?.nseCode : item.alias || item.name;
  const entityRedirect =
    item.type !== 'stock' ? '/markets' : `/stock/${item.stockId}/${slugify(item.name)}`;
  const tileClass =
    item.name === 'Dollar - Rupee'
      ? getAllDirClassName(item.chg, 'negative', 'positive')
      : getAllDirClassName(item.chg, 'positive', 'negative');
  const [bookmarked, setBookmarked] = useState(false);
  return (
    <>
      <div styleName={`tile ${tileClass}`}>
        <a href={entityRedirect}>
          <div>
            <span className="truncate-overflow">{stockName}</span>
            {item.value ? (
              <span style={{ color: item.chgp > 0 ? 'var(--success-color)' : 'var(--negative-red)' }}>
                {Number(item.value).toFixed(2)}
                <SvgIcon
                  type={`${item.chgp > 0 ? 'upArrowCircle' : 'downArrowCircle'}`}
                  source="image"
                  height="13"
                  width="13"
                />
              </span>
            ) : (
              '-'
            )}
            {item.value ? (
              <span styleName="">
                {item.chgp > 0 ? `+${Number(item.chgp).toFixed(2)}` : Number(item.chgp).toFixed(2)}%
              </span>
            ) : (
              <span>No Data Available</span>
            )}
          </div>
        </a>
      </div>

      {stockNotification && (
        <Notification message={stockNotification} stockStatus={stockStatus} failure={failure} />
      )}
    </>
  );
};

Tile.propTypes = {
  item: objectOf(any).isRequired,
  checkUserLoggedIn: func,
  stockNotification: string,
  stockStatus: string,
  failure: bool,
  loggedIn: bool,
};

class MarketBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      selectedItem: '',
    };
  }

  componentDidMount() {
    const entityIds = this.props.entities
      .slice(0, 4)
      .map((entity) => entity.id)
      .join(',');
    this.getEntityDetails(entityIds);
    this.marketBoxRefreshInterval = setInterval(this.getEntityDetails.bind(this, entityIds), 60000);
  }

  componentWillUnmount() {
    if (this.marketBoxRefreshInterval) clearInterval(this.marketBoxRefreshInterval);
  }

  checkUserLoggedIn = (item) => {
    if (this.props.userLoggedIn) {
    } else {
      this.setState({ selectedItem: item });
    }
  };

  updateMember = async (member) => {
    await this.props.reloadMember(member);
  };

  triggerGetAction = (data) => {
    this.props.getStocksFromFirebase(data);
  };

  getEntityDetails = (params) => {
    fetch(`/next/feapi/stock?entity-ids=${params}`)
      .then((response) => response.json())
      .then((entities) => this.setState({ entities }))
      .catch(() => this.setState({ entities: [] }));
  };

  render() {
    return this.state.entities.length ? (
      <div styleName={`container`}>
        {this.state.entities.map((entity) => (
          <Tile
            item={entity}
            key={entity.name}
            stockNotification={this.props.stockNotification}
            checkUserLoggedIn={this.checkUserLoggedIn}
            stockStatus={this.props.stockStatus}
            failure={this.props.failure}
            loggedIn={this.props.userLoggedIn}
          />
        ))}
      </div>
    ) : (
      <div />
    );
  }
}

MarketBox.propTypes = {
  entities: arrayOf(object).isRequired,
  showLoader: func,
  addEntity: func,
  removeLoader: func,
  maxStock: object,
  getStocksFromFirebase: func,
  stockNotification: string,
  stocks: object,
  showNotification: func,
  userLoggedIn: bool,
  publicIntegrations: object.isRequired,
  integrations: object.isRequired,
  reloadMember: func,
  subscriptionStatus: string,
  stockStatus: string,
  failure: bool,
  memberDetail: object,
};

const mapStateToProps = (state) => {
  const { pianoUserDetails = {} } = state;
  const memberDetail = pianoUserDetails.member || {};
  const isUserValid = memberDetail.uid || null;
  return {
    maxStock: get(state, ['qt', 'config', 'features', 'stock_watchlist_tab', 'max_stock'], {}),
    stocks: get(state, ['userDetails', 'stockWatchList', 'stocks'], {}),
    stockNotification: get(state, ['userDetails', 'stockNotification'], null),
    userLoggedIn: !!isUserValid,
    subscriptionStatus: get(state, ['userDetails', 'subscriptionStatus'], null),
    publicIntegrations: state.qt.config['public-integrations'] || {},
    integrations: state.qt.config.integrations || {},
    stockStatus: get(state, ['userDetails', 'stockStatus'], null),
    failure: get(state, ['userDetails', 'failure'], null),
    memberDetail: memberDetail,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getStocksFromFirebase: function (payload) {
      dispatch({
        type: BQ_STOCK_WATCHLIST_GET,
        payload: payload,
      });
    },
    addEntity: function (payload, maxStock, data, subscriptionStatus) {
      const maxAddStock =
        subscriptionStatus === 'active' ? maxStock.subscribed : maxStock.non_subscribed;
      if (size(data.stocks) + 1 > maxAddStock) {
        const message =
          props.subscriptionStatus !== 'active'
            ? 'You’ve added the maximum number of stocks permissible. &nbsp;<a target="_blank" rel="noopener noreferrer"href = "/bq-blue-subscription?utm_source=source-mybq,medium-watchlist,campaign–stock-exceed" styleName="subscribe_link"><b>Subscribe to add more</b></a>'
            : 'You have reached free stocks limit.';
        dispatch({
          type: BQ_STOCK_NOTIFICATIONS_SET,
          payload: {
            message: message,
          },
        });
        dispatch({
          type: BQ_NOTIFICATION_LIST,
          payload: {
            notification: setTimeout(() => {
              dispatch({ type: BQ_STOCK_NOTIFICATIONS_DISABLE });
            }, 3000),
          },
        });
        return Promise.resolve();
      }
      return addEntityToFirebase(payload.type, payload, data.memberId, data)
        .then(() => {
          dispatch({ type: BQ_ENTITY_WATCHLIST_ADD_FROM_STORY, payload: payload });
        })
        .then(() => {
          dispatch({
            type: BQ_NOTIFICATION_LIST,
            payload: {
              notification: setTimeout(() => {
                dispatch({ type: BQ_STOCK_NOTIFICATIONS_DISABLE });
              }, 3000),
            },
          });
        });
    },
    showNotification: (message) => {
      dispatch({
        type: BQ_STOCK_ALREADY_ADDED,
        payload: {
          message: [message],
        },
      });
      dispatch({
        type: BQ_NOTIFICATION_LIST,
        payload: {
          notification: setTimeout(() => {
            dispatch({ type: BQ_STOCK_NOTIFICATIONS_DISABLE });
          }, 3000),
        },
      });
    },
    showLoader: () => {
      dispatch({
        type: SHOW_LOADER,
      });
    },
    removeLoader: () => {
      dispatch({
        type: REMOVE_LOADER,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketBox);
