import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ClientSideOnly } from '@quintype/components';
import { connect } from 'react-redux';
import Loader from '../atoms/icons/loader';
import { PATH } from '../../constants';

function LoadingIndicator() {
  return (
    <div className="loader">
      <Loader color={'#000'} />
    </div>
  );
}
class AmpPageBase extends Component {
  componentDidMount() {}

  render() {
    return (
      <ClientSideOnly serverComponent={LoadingIndicator}>
        <LoadingIndicator />
      </ClientSideOnly>
    );
  }
}

AmpPageBase.propTypes = {
  data: PropTypes.object,
  publicIntegrations: PropTypes.objectOf(PropTypes.any).isRequired,
  integrations: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  publicIntegrations: state.qt.config['public-integrations'] || {},
  integrations: state.qt.config.integrations || {},
});

export const AmpLogin = connect(mapStateToProps, null)(AmpPageBase);
