import React from 'react';
import loadjs from 'loadjs';

class BrightCoveBase extends React.Component {
  constructor(props) {
    super(props);
    this.player = null;
    this.mountPlayer = this.mountPlayer.bind(this);
  }

  componentDidMount() {
    const {
      'account-id': accountId,
      'player-id': playerId,
      'player-media': playerMedia
    } = this.props.video.metadata;
    const scriptSource = `https://players.brightcove.net/${accountId}/${playerId}_${playerMedia}/index.min.js`;
    loadjs(scriptSource, { success: this.mountPlayer });
  }

  componentWillUnmount() {
    if (this.player) this.player.dispose();
    this.player = null;
  }

  mountPlayer() {
    if (window.videojs) {
      const player = window.videojs(this.videoNode);
      this.player = player;
      player.muted(this.props.mute);
      if (this.props.autoPlay) player.play();
    }
  }

  render() {
    const {
      'account-id': accountId,
      'player-id': playerId,
      'player-media': playerMedia,
      'video-id': videoId
    } = this.props.video.metadata;

    return (
      <div className="brightcove-video__container">
        <video
          ref={videoNode => (this.videoNode = videoNode)}
          data-video-id={videoId}
          data-account={accountId}
          data-player={playerId}
          data-embed={playerMedia}
          data-application-id
          controls
          className="video-js brightcove-video"
          width="100%"
        />
      </div>
    );
  }
}

function BrightCove({ element }) {
  return <BrightCoveBase video={element} mute autoPlay={false} />;
}

export { BrightCove };
