import React from 'react';
import { StoryPage } from './story';

class StoryPublicPreview extends React.Component {
  render(props) {
    return <StoryPage {...this.props} preview />;
  }
}

export { StoryPublicPreview };
