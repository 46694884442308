/*eslint-disable*/
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect, Provider, useSelector } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import PropTypes, { any } from 'prop-types';
import get from 'lodash/get';
import take from 'lodash/take';
import unescape from 'lodash/unescape';
import { isMobileOnly as isMobile } from 'react-device-detect';
import { truncate } from '../../helpers/utils';
import StoryBaseTemplate from './story-base-template';
import storyElementWithCustomTemplates from '../story/story-elements/index';
import MarketBox from '../story/market-box/market-box';
import AdWithPlaceholder from '../molecules/ad-with-placeholder';
import reducers from '../../../store/reducers';

const getPartialContent = (cards = []) => {
  const storyCards = take(cards);

  const partialStory = storyCards.map((card) => {
    const storyCard = Object.assign({}, card);
    const storyElements = take(storyCard['story-elements']);
    if (storyElements[0].type === 'text') {
      storyElements[0] = Object.assign({}, storyElements[0]);
      storyElements[0].text = truncate(
        unescape(storyElements[0].text.replace(/<[^>]+>/g, '')),
        440,
      );
    }

    storyCard['story-elements'] = storyElements;

    return storyCard;
  });

  return partialStory;
};

function changeSymbolColor(element) {
  const newElement = { ...element };
  if (newElement.type === 'text') {
    newElement.text = newElement.text.replace(/♥|♦/gi, function (matched) {
      return `<span style="color:red;">${matched}</span>`;
    });
  }
  return newElement;
}

const renderCards = (story = {}, storyCards = [], preview = false) => {
  const cardLength = storyCards.length;
  return (
    <React.Fragment>
      {storyCards.map((card, index) => {
        const cardAttributeTemplate = get(card, ['metadata', 'attributes', 'template', 0], '');
        const cardAttributeTicker = get(card, ['metadata', 'attributes', 'ticker'], []);
        const classes = cardAttributeTemplate ? `story-card-${cardAttributeTemplate}` : '';
        const storyStyleType = get(story, ['metadata', 'story-attributes', 'storystyletype', 0]);
        const storyElements = card['story-elements'];

        return (
          <React.Fragment key={card.id}>
            <div id={`card-${card.id}`} key={card.id} className={`${classes} print-story-card`}>
              {cardAttributeTicker.length ? <MarketBox entities={cardAttributeTicker} /> : null}
              {storyElements.map((element, index) => {
                const storyElement =
                  storyStyleType === 'cards' ? changeSymbolColor(element) : element;

                return (
                  <React.Fragment key={index}>
                    {storyElementWithCustomTemplates(
                      card,
                      storyElement,
                      story,
                      cardAttributeTemplate,
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            {index === Math.round(cardLength / 2) - 1 && !preview ? <></> : ''}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const BlankStory = ({
  story,
  partialAccess,
  updateAccess,
  isLoading,
  privateMode,
  mostRead,
  qt,
  reg_paywall,
  pageType,
}) => {
  const isLoggedIn = useSelector((state) => get(state, ['userData', 'isLoggedIn'], false));
  const { cards } = story;
  const storyCards =
    partialAccess || (!isLoggedIn && reg_paywall) ? getPartialContent(cards) : cards;
  let config = {
    config: {
      'cdn-image': 'media.assettype.com',
    },
  };

  config = {
    ...config,
    ...qt,
  };

  const rootReducer = combineReducers({
    ...reducers,
    qt: (state = config, action) => state,
  });
  const store = createStore(rootReducer);
  const isTestPage = pageType === 'story-page-test';

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    const paragraphs = document.getElementsByTagName('p');
    let filteredCount = 0;
    for (let index = isMobile ? 1 : 0; index < paragraphs.length - 1; index++) {
      if (
        document.getElementsByTagName('p')[index].parentElement?.tagName !== 'LI' &&
        document.getElementsByTagName('p')[index].parentElement?.className !== 'paragraphs'
      ) {
        document.getElementsByTagName('p')[index].className = 'filteredParagraphs';
        filteredCount++;
      }
    }

    let adNumber = 0;
    const adLimit = 3;
    function insertRelatedSearchScript() {
      const container = document.getElementById("relatedsearches1");
      if (!container) {
        return;
      }

      // Check if _googCsa is available
      if (typeof window._googCsa !== "function") {

        const googleScript = document.createElement("script");
        googleScript.src = "https://www.google.com/adsense/search/ads.js";
        googleScript.async = true;

        //  Wait for script to load before executing _googCsa
        googleScript.onload = () => {
          executeGoogleAds();
        };

        document.head.appendChild(googleScript);
      } else {
        console.log(" _googCsa is already loaded. Executing...");
        executeGoogleAds();
      }
    }

    //  Separate function to execute _googCsa safely
    function executeGoogleAds() {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.charset = "utf-8";
      script.innerHTML = `
    var pageOptions = {
      "pubId": "partner-pub-7641565019577886",
      "styleId": "8412670786",
      "relatedSearchTargeting": "content",
      "resultsPageBaseUrl": "https://www.ndtvprofit.com/search",
      "resultsPageQueryParam": "q",
      "hl": "en",
    };

    var rsblock1 = {
      "container": "relatedsearches1",
      "relatedSearches": 5
    };

    if (typeof _googCsa === "function") {
      _googCsa('relatedsearch', pageOptions, rsblock1);
    } else {
      console.error("❌ _googCsa is still not available!");
    }
  `;

      document.body.appendChild(script);
    }

    if (isMobile) {
      let adIndex = 0;
      let secondAdInserted = false;
      let secondAdParagraphIndex = -1;

      while (adNumber < adLimit) {
        adNumber++;
        let count = 0;
        const allQualifiedParas = [...document.getElementsByClassName('filteredParagraphs')];

        for (let j = adIndex; j < allQualifiedParas.length; j++) {
          count += allQualifiedParas[j]?.innerText.split(' ').length;

          if (count > 90) {
            const id = `MobilePara${adNumber}`;
            const d = document.createElement('div');
            d.id = id;
            d.className = 'responsive-ad content-center';
            allQualifiedParas[j]?.appendChild(d);

            ReactDOM.render(
              <>
                <Provider store={store}>
                  <AdWithPlaceholder
                    height="280px"
                    width="336px"
                    adUnit={adNumber === 1 ? 'Mid_300x250_1' : `Mid_300x250_${adNumber}`}
                    sizes={[
                      [336, 280],
                      [300, 250],
                    ]}
                  />
                </Provider>
              </>,
              document.getElementById(id),
            );

            //  Capture index of second ad paragraph
            if (adNumber === 2) {
              secondAdInserted = true;
              secondAdParagraphIndex = j;
            }

            //  Insert related searches div **after two additional paragraphs** below the second ad
            if (secondAdInserted && allQualifiedParas[secondAdParagraphIndex + 2]) {
              const relatedSearchDiv = document.createElement('div');
              relatedSearchDiv.id = 'relatedsearches1';
              allQualifiedParas[secondAdParagraphIndex + 2]?.insertAdjacentElement(
                'afterend',
                relatedSearchDiv,
              );
              insertRelatedSearchScript();
              secondAdInserted = false; // Ensuring it only inserts once
            }

            adIndex = j + 1;
            break;
          }
        }
      }
    } else {
      let adIndex = 0;
      let secondAdInserted = false;
      let secondAdParagraphIndex = -1;

      while (adNumber < adLimit) {
        adNumber++;
        let count = 0;
        const allQualifiedParas = [...document.getElementsByClassName('filteredParagraphs')];

        for (let j = adIndex; j < allQualifiedParas.length; j++) {
          count += allQualifiedParas[j]?.innerText.split(' ').length;
          const adPlacementCount = adIndex === 0 ? 100 : 150;

          if (count >= adPlacementCount) {
            const id = `Para${adNumber}`;
            const d = document.createElement('div');
            d.id = id;
            d.className = 'responsive-ad content-center';
            allQualifiedParas[j]?.appendChild(d);

            ReactDOM.render(
              <>
                <Provider store={store}>
                  <AdWithPlaceholder
                    height="90px"
                    width="728px"
                    adUnit={`Mid_728x90_${adNumber}`}
                    sizes={[[728, 90]]}
                  />
                </Provider>
              </>,
              document.getElementById(id),
            );

            //  Capture index of second ad paragraph
            if (adNumber === 2) {
              secondAdInserted = true;
              secondAdParagraphIndex = j;
            }

            // Insert related searches div **after 50 words** below the second ad
            if (secondAdInserted) {
              let wordCount = 0;
              let insertIndex = secondAdParagraphIndex + 1; // Start checking paragraphs after the second ad

              while (wordCount < 24 && allQualifiedParas[insertIndex]) {
                wordCount += allQualifiedParas[insertIndex]?.innerText.split(' ').length;
                insertIndex++;
              }

              if (wordCount >= 24 && allQualifiedParas[insertIndex]) {
                const relatedSearchDiv = document.createElement('div');
                relatedSearchDiv.id = 'relatedsearches1';
                allQualifiedParas[insertIndex]?.insertAdjacentElement('afterend', relatedSearchDiv);
                insertRelatedSearchScript();
                secondAdInserted = false; // Ensuring it only inserts once
              }
            }

            adIndex = j + 1;
            break;
          }
        }
      }
    }
  }, []);

  return (
    <StoryBaseTemplate
      story={story}
      content={renderCards(story, storyCards)}
      previewContent={renderCards(story, getPartialContent(cards), true)}
      partialAccess={partialAccess || (!isLoggedIn && reg_paywall)}
      updateAccess={updateAccess}
      isLoading={isLoading}
      privateMode={privateMode}
      mostRead={mostRead}
      reg_paywall={reg_paywall}
    />
  );
};

BlankStory.propTypes = {
  story: PropTypes.objectOf(PropTypes.any).isRequired,
  partialAccess: PropTypes.bool,
  updateAccess: PropTypes.func,
  isLoading: PropTypes.bool,
  privateMode: PropTypes.bool,
  mostRead: PropTypes.object,
  qt: any,
  reg_paywall: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  qt: get(state, ['qt'], ''),
  pageType: get(state, ['qt', 'pageType'], 'story-page-test'),
});

export default connect(mapStateToProps, () => ({}))(BlankStory);
