import React from 'react';
import PropTypes from 'prop-types';

const EditorsTakeReadMore = ({ element, story }) => {
  const storyUrl =
    story['linked-stories'] &&
    `/${story['linked-stories'][element.metadata['linked-story-id']].slug}`;
  return (
    <div className="editors-take-read-more-wrapper">
      <a target="_blank" href={storyUrl} className="editors-take-read-more">
        Read More
      </a>
    </div>
  );
};

EditorsTakeReadMore.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  story: PropTypes.objectOf(PropTypes.any).isRequired
};

export default EditorsTakeReadMore;
