import React from 'react';
import PropTypes from 'prop-types';
import './sort-menu.module.css';
import AngleDown from '../icons/angle-down';

class RenderSortMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpened: false
    };
    this.menuItemActions = this.menuItemActions.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  menuItemActions() {
    this.props.orderChanged(this.props.menuData.order);
    this.handleClick();
  }

  handleClick() {
    if (!this.state.menuOpened) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      menuOpened: !prevState.menuOpened
    }));
  }

  handleOutsideClick(e) {
    if (this.node.contains(e.target)) return null;
    this.handleClick();
  }

  render() {
    const { menuData } = this.props;
    const menuStateClass = this.state.menuOpened ? 'menu-opened' : '';
    return (
      <div
        styleName={`sort-menu ${menuStateClass}`}
        ref={node => {
          this.node = node;
        }}
      >
        <div styleName="sort-menu-label" onClick={this.handleClick}>
          {menuData.label} <AngleDown fontSize="7px" color='white'/>
        </div>
        <ul styleName="sort-menu-list">
          <li onClick={this.menuItemActions} styleName="sort-menu-item">
            {menuData.listItem}
          </li>
        </ul>
      </div>
    );
  }
}

RenderSortMenu.propTypes = {
  menuData: PropTypes.objectOf(PropTypes.any).isRequired,
  orderChanged: PropTypes.func.isRequired
};

const SortMenu = ({ active, orderChanged }) => {
  const menuData = {
    label: 'Oldest First',
    listItem: 'Latest First',
    order: 'desc'
  };

  if (active === 'desc') {
    menuData.label = 'Latest First';
    menuData.listItem = 'Oldest First';
    menuData.order = 'asc';
  }

  return <RenderSortMenu menuData={menuData} orderChanged={orderChanged} />;
};

SortMenu.propTypes = {
  active: PropTypes.string.isRequired,
  orderChanged: PropTypes.func.isRequired
};

export default SortMenu;
