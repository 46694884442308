import React from 'react';
import { object } from 'prop-types';
import { Link } from '@quintype/components';

import { BQLink } from '../../atoms/bqlink';
import CardImage from '../../atoms/cardimage';
import { getHeadline, getStorySlug, isBqBlueExclusiveStory } from '../../../helpers/utils';
import './cardImageAuthorHeadline.m.css';

const CardImageAuthorHeadline = ({ story }) => {
  const authorList = story.authors || [];

  return (
    <div styleName="wrapper" className="card-image-author-headline">
      <div styleName="content">
        <BQLink
          story={story}
          href={`${getStorySlug(story)}?src=infy_stry`}
          aria-label={`${getHeadline(story)}`}
        >
          <CardImage
            story={story}
            defaultWidth={200}
            aspectRatio={[6, 5]}
            imageType="image6x5"
            isBottomBqBlue={isBqBlueExclusiveStory(story)}
          />
        </BQLink>

        <div styleName="story-content">
          <BQLink
            story={story}
            href={`${getStorySlug(story)}?src=infy_stry`}
            aria-label={`read more about ${getHeadline(story)}`}
          >
            <div styleName="headline">{getHeadline(story, true)}</div>
          </BQLink>
          <div styleName="author-list">
            {authorList.map((item, index) => (
              <Link
                key={index}
                styleName="author"
                href={`/author/${item.id}/${item.slug}`}
                aria-label={`read more about ${item.slug}`}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

CardImageAuthorHeadline.propTypes = {
  story: object.isRequired
};

export default CardImageAuthorHeadline;
