import React from 'react';
import PropTypes from 'prop-types';
import { Scrollchor } from 'react-scrollchor';
import './new-updates.module.css';

const NewUpates = ({ cardId, newUpdates, removeUpdates }) => (
  <Scrollchor
    to={cardId}
    disableHistory
    styleName="link"
    animate={{ offset: -90 }}
    afterAnimate={removeUpdates}
  >
    {newUpdates} New Update(s). Click to Refresh
  </Scrollchor>
);

NewUpates.propTypes = {
  cardId: PropTypes.string.isRequired,
  newUpdates: PropTypes.number.isRequired,
  removeUpdates: PropTypes.func.isRequired
};

export default NewUpates;
