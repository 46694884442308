import React from 'react';
import { BQLink } from '../../../atoms/bqlink';
import './also-read-multi-links.m.css';

const AlsoReadMultiLinks = ({ element }) => {
  return (
    <div styleName="also-read-multi-links-ctx">
      <div styleName="title">
        <h4 styleName="title-heading">Also Read</h4>
      </div>
      <BQLink href={element?.metadata?.['cta-url']} target="_blank">
        <h5 styleName="cta-link">{element?.metadata?.['cta-title']}</h5>
      </BQLink>
    </div>
  );
};

export default AlsoReadMultiLinks;