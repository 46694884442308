import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Slider from 'react-slick';
import LightBoxImage from '../image/image';
import RightArrow from '../../../atoms/icons/right-arrow-small';
import LeftArrow from '../../../atoms/icons/left-arrow-small';
import './image-slideshow.m.css';

const ImageSlideshow = props => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 600,
    nextArrow: (
      <div className="next">
        <RightArrow color="fourth-color" />
      </div>
    ),
    prevArrow: (
      <div className="prev">
        <LeftArrow color="fourth-color" />
      </div>
    ),
    slidesToShow: 1
  };

  const { element, story, card } = props;
  const slideshowList = map(element['story-elements'], imageElement => (
    <div className="story-element-image-slideshow__slide" key={imageElement.id}>
      <LightBoxImage
        element={imageElement}
        parentElement={element}
        story={story}
        card={card}
        type="slideshow"
      />
    </div>
  ));

  return (
    <section className="story-element-image-slideshow" styleName='story-element-image-slideshow'>
      <Slider {...sliderSettings}>{slideshowList}</Slider>
    </section>
  );
};

ImageSlideshow.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  story: PropTypes.objectOf(PropTypes.any).isRequired,
  card: PropTypes.objectOf(PropTypes.any)
};

ImageSlideshow.defaultProps = {
  card: null
};

export default ImageSlideshow;
