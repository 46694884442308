import React, { useEffect, useState } from 'react';
import { object, func, bool } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import get from 'lodash/get';
import { InfiniteStoryBase, LazyLoadImages } from '@quintype/components';
import DesktopStickyAd from '../../molecules/desktop-sticky-ad';
import getTemplate from '../../story-templates/templates';
import MoengageHelper from '../../../helpers/moengage-helper';
import { openLinkInNewTab, getBqExclusiveStories } from '../../../helpers/utils';
import { getAboveFoldStories } from '../../../helpers/api';
import './story.m.css';
import WithAccess from '../../with-access';
import { GoogleOAuthProvider } from '@react-oauth/google';

class ViewedStoriesStore {
  constructor(options = {}) {
    this.options = options;
    this.storageLimit = options.storageLimit || 20;
  }

  static build(options = {}) {
    if (!global.localStorage) {
      return {
        getViewedStoryIds: () => [],
        addViewedStoryId: () => { },
      };
    }
    return new ViewedStoriesStore(options);
  }

  getViewedStoryEntiries() {
    const viewedStoryIds = global.localStorage.getItem('viewed-stories');
    if (!viewedStoryIds || viewedStoryIds === '') {
      return [];
    }
    try {
      return JSON.parse(viewedStoryIds) || [];
    } catch (e) {
      return [];
    }
  }

  getViewedStoryIds() {
    return this.getViewedStoryEntiries().map((entry) => entry.id);
  }

  removeOldStoryIds(story) {
    const expiryTime = Date.now() - this.options.expiryTime || 8 * 60 * 60 * 1000;
    return story['viewed-at'] > expiryTime;
  }

  addViewedStoryId(storyId) {
    const viewedStories = this.getViewedStoryEntiries().filter(this.removeOldStoryIds.bind(this));

    if (viewedStories?.length >= this.storageLimit) {
      viewedStories?.shift();
    }

    viewedStories.push({
      'viewed-at': Date.now(),
      id: storyId,
    });

    global.localStorage.setItem('viewed-stories', JSON.stringify(viewedStories));
  }
}

const StoryPageBase = (props) => {
  const storyApiLimit = 50;
  const [viewedStoriesStore, setViewedStoriesStore] = useState(null);
  const [topStoryIds, setTopStoryIds] = useState([]);
  const [isInfiniteScroll, setIsInfiniteScroll] = useState(false);
  const [qtypeSession, setQtypeSession] = useState([]);
  const [storyTemplate, setStoryTemplate] = useState(props?.data?.story?.['story-template']);
  const userData = useSelector((state) => get(state, ['userData']));
  const oauth = useSelector((state) =>
    get(state, ['qt', 'config', 'integrations', 'google-oauth'], ''),
  );
  const { isSubscriber } = userData || {};

  useEffect(() => {
    const { story } = props.data;
    setQtypeSession(
      document.cookie.split('; ').filter((item) => item.startsWith('qtype-session=')),
    );

    if (global.COMSCORE) {
      global.COMSCORE.beacon(global.COMSCORE_OPTS);
    }

    openLinkInNewTab('.story-element a');

    setViewedStoriesStore(
      ViewedStoriesStore.build({
        expiryTime: props.storySnapshotsConfig.expiry_time || null,
        storageLimit: Math.ceil(storyApiLimit / 2),
      }),
    );

    global.Moengage && MoengageHelper.storyView(story);
    global.Moengage && MoengageHelper.storyViewAttribute(story.headline);
  }, []);

  useEffect(() => {
    const storyDataTemplate = props?.data?.story?.['story-template'];
    if (isSubscriber === true) {
      if (
        storyDataTemplate === 'text' ||
        storyDataTemplate === 'syndicated' ||
        storyDataTemplate === null
      ) {
        setStoryTemplate('premium-story');
      } else {
        setStoryTemplate(storyDataTemplate);
      }
    }
    if (isSubscriber === false) {
      if (storyDataTemplate === null) {
        setStoryTemplate('text');
      } else {
        setStoryTemplate(storyDataTemplate);
      }
    }
  }, [isSubscriber]);

  useEffect(() => {
    if (viewedStoriesStore) {
      viewedStoriesStore.addViewedStoryId(props.data.story.id);
    }
  }, [viewedStoriesStore]);

  useEffect(() => {
    if (props.data.bottomData) {
      const [selectedStoriesCollection, videoCollection, editorCollection] = get(
        props,
        ['data', 'bottomData', 'collection', 'items'],
        [null, null, null],
      );

      if (selectedStoriesCollection && selectedStoriesCollection.items) {
        const stories = selectedStoriesCollection
          ? getBqExclusiveStories(selectedStoriesCollection.items)
          : [];
        const ids = stories.map((c) => c.id);

        setTopStoryIds(ids);
      }

      props.setBottomStoryData({
        selectedStoriesCollection,
        videoCollection,
        editorCollection,
      });
    }
  }, [props.data.bottomData]);

  useEffect(() => {
    if (qtypeSession.length > 0) {
    }
  }, [qtypeSession]);

  const onInitialItemFocusHandler = (item) => {
    setTimeout(() => {
      viewedStoriesStore.addViewedStoryId(item.story.id);
    }, 0);
  };

  const storyPageLoadItems = (pageNumber) => {
    if (props?.infiniteScroll) {
      if (isInfiniteScroll) return Promise.resolve([]);

      const latestStoriesPromise = getLatestStoryList(pageNumber);

      return Promise.all([latestStoriesPromise]).then(([latestStories, bqBlueStories]) => {
        latestStories = Array.isArray(latestStories) ? latestStories : [];
        bqBlueStories = Array.isArray(bqBlueStories) ? bqBlueStories : [];
        const allStories = [];
        let i = 0;
        while (i < latestStories.length || i < bqBlueStories.length) {
          if (i < bqBlueStories.length) {
            allStories.push(bqBlueStories[i]);
          }
          if (i < latestStories.length) {
            allStories.push(latestStories[i]);
          }
          i++;
        }

        setIsInfiniteScroll(true);

        return allStories;
      });
    } else {
      return Promise.resolve([]);
    }
  };

  const getLatestStoryList = (pageNumber) => {
    const fullStories = 1;
    const offset = storyApiLimit * pageNumber;

    return getAboveFoldStories(storyApiLimit, offset, topStoryIds)
      .get()
      .json(({ items = [] }) => items.map((item) => item.story))
      .then((stories) => {
        const viewedStoryIds = viewedStoriesStore.getViewedStoryIds();
        return stories.filter(
          (story) =>
            !viewedStoryIds.find((viewedStoryId) => viewedStoryId === story.id) &&
            !story.sections.find((section) => section.slug === 'exclusives') &&
            get(story, ['cards', 0, 'story-elements', 0, 'type'], '') === 'text',
        );
      })
      .then((stories) =>
        stories.map((story, index) => {
          story['story-template'] =
            pageNumber < fullStories - 1 && index < fullStories - 1
              ? story['story-template']
              : 'story-snapshot';
          return { story, otherProp: 'value' };
        }),
      );
  };

  // eslint-disable-next-line react/display-name
  const getStoryTemplate =
    (hasAccess = true, reg_paywall) =>
      ({
        // eslint-disable-next-line react/prop-types
        story,
        // eslint-disable-next-line react/prop-types
        singleVideoPageCollection,
        // eslint-disable-next-line react/prop-types
        mostRead,
        ...props
      }) => {
        // eslint-disable-next-line react/prop-types
        const Template = getTemplate(storyTemplate);

        // eslint-disable-next-line react/prop-types
        if (props.index === 0) {
          return (
            <GoogleOAuthProvider clientId={get(oauth, ['client-id'], '')}>
              <div>
                <Template
                  story={story}
                  partialAccess={!hasAccess}
                  reg_paywall={reg_paywall}
                  singleVideoPageCollection={singleVideoPageCollection}
                  mostRead={mostRead}
                />
              </div>
            </GoogleOAuthProvider>
          );
        }

        return (
          <GoogleOAuthProvider clientId={get(oauth, ['client-id'], '')}>
            <div styleName="infinite-stories" className="container">
              <Template
                story={story}
                partialAccess={!hasAccess}
                reg_paywall={reg_paywall}
                singleVideoPageCollection={singleVideoPageCollection}
                mostRead={mostRead}
              />
            </div>
          </GoogleOAuthProvider>
        );
      };

  if (props.preview) return getStoryTemplate()(props.data);
  if (props.subscription.enabled) {
    const { story } = props.data;
    return (
      <WithAccess
        storyAccessLevel={story['access-level-value']}
        storyAccess={story.access}
        isSubscriber
      >
        {({ hasAccess, access }) => {
          return (
            <LazyLoadImages margin="50px">
              <div style={{ display: 'block' }}>
                <InfiniteStoryBase
                  {...props}
                  neverHideItem={true}
                  render={getStoryTemplate(props.data.terminal || props.data.preview || hasAccess, access)}
                  loadItems={storyPageLoadItems}
                  onInitialItemFocus={(item) => onInitialItemFocusHandler(item)}
                  doNotChangeUrl={true}
                />
              </div>
            </LazyLoadImages>
          );
        }}
      </WithAccess>
    );
  }

  return (
    <LazyLoadImages margin="50px">
      <>
        <InfiniteStoryBase
          {...props}
          neverHideItem={true}
          render={getStoryTemplate()}
          loadItems={storyPageLoadItems}
          onInitialItemFocus={(item) => onInitialItemFocusHandler(item)}
          doNotChangeUrl={true}
        />
        <DesktopStickyAd reduceZIndex />
      </>
    </LazyLoadImages>
  );
};

function mapStateToProps(state) {
  const { bottomStoryData } = state;

  return {
    loadedModules: state.loadedModules,
    storySnapshotsConfig: state.qt.config.publisher.story_snapshots || {},
    subscription: state.qt.config.features.subscription || {},
    malibuHomePage: state.qt.config.features.malibu.home_page || false,
    meterShown: state.meterShown,
    bottomStoryData: bottomStoryData || {},
    infiniteScroll: get(state, ['qt', 'config', 'integrations', 'story', 'infiniteScroll'], false),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendDmpStatus: () => {
      dispatch({
        type: 'BQ_SEND_DMP_STATUS',
      });
    },
    sendCurrentSectionName: (sectionName) => {
      dispatch({
        type: 'BQ_CURRENT_SECTION_NAME',
        value: sectionName,
      });
    },
    setBottomStoryData: (data) => {
      dispatch({
        type: 'BOTTOM_STORY_DATA',
        data,
      });
    },
  };
}

StoryPageBase.propTypes = {
  sendDmpStatus: func,
  loadedModules: object,
  data: object.isRequired,
  sendCurrentSectionName: func,
  storySnapshotsConfig: object,
  malibuHomePage: bool,
  preview: bool,
  subscription: object.isRequired,
  meterShown: bool.isRequired,
  setBottomStoryData: func,
  bottomStoryData: object,
  infiniteScroll: bool,
};

const StoryPage = connect(mapStateToProps, mapDispatchToProps)(StoryPageBase);

export { StoryPage };
