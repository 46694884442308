import React from 'react';
import { objectOf, string, any, number, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import MediaQuery from 'react-responsive';
import storyElementWithCustomTemplates from '../story-elements/index';
import Share from '../share/share';
import MarketBox from '../market-box/market-box';
import { getTitleElement } from '../../../helpers/utils';
import { isToday } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DATE_FORMATS } from '../../../constants';
import { REMOVE_CLICK_KEY_HIGHLIGHT } from '../../../../store/actions';
import TrackVisibility from '../../track-visibility';
import AdWithPlaceholder from '../../molecules/ad-with-placeholder';
import { isMobileOnly as isMobile } from 'react-device-detect';
import './story-card.module.css';

class StoryCard extends React.Component {
  static getCardTitle(elements, defaultHeading) {
    const titleElement = find(
      elements,
      (element) => element.type === 'title' && element.subtype === null
    );
    return get(titleElement, 'text', defaultHeading);
  }

  constructor(props) {
    super(props);
    this.state = {
      expanded:
        this.props.cardIndex < this.props.expandedCards ||
        this.props.sharedCardId === this.props.card.id,
    };
    this.expandCollapseHandler = this.expandCollapseHandler.bind(this);
  }

  /* eslint-disable */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.order !== nextProps.order) {
      this.setState({
        expanded:
          nextProps.cardIndex < nextProps.expandedCards ||
          this.props.sharedCardId !== this.props.card.id,
      });
    }

    if (nextProps.cardHighlighted === this.props.card.id) {
      this.setState({
        expanded: true,
      });
    }
    if (this.props.sharedCardId && this.props.sharedCardId === this.props.card.id) {
      this.setState({
        expanded: true,
      });
    }
  }
  /* eslint-enable */

  elements(card, elements, story, cardAttributeTemplate, cardAttributeTicker) {
    if (this.state.expanded) {
      return (
        <React.Fragment>
          {cardAttributeTicker.length ? <MarketBox entities={cardAttributeTicker} /> : null}
          {elements.map((element) =>
            storyElementWithCustomTemplates(card, element, story, cardAttributeTemplate)
          )}
        </React.Fragment>
      );
    }

    const titleElement = getTitleElement(elements);

    if (titleElement) {
      return (
        <div styleName="clickable" onClick={this.expandCollapseHandler} role="presentation">
          {storyElementWithCustomTemplates(card, titleElement, story, cardAttributeTemplate)}
        </div>
      );
    }
    return (
      <div
        styleName="clickable"
        onClick={this.expandCollapseHandler}
        role="presentation"
        className="story-element story-element-title"
      >
        <h2>{story.headline}</h2>
      </div>
    );
  }

  expandCollapseHandler() {
    if (
      this.state.expanded &&
      this.props.cardHighlighted &&
      this.props.cardHighlighted === this.props.card.id
    ) {
      this.props.removeKeyHighlight();
    }
    if (
      this.state.expanded &&
      this.props.sharedCardId &&
      this.props.sharedCardId === this.props.card.id
    ) {
      this.props.unsetSharedCardId();
    }

    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  getCardTime() {
    const time = new Date(this.props.card['card-added-at']);

    if (isToday(time)) {
      return formatInTimeZone(time, 'Asia/Kolkata', DATE_FORMATS.HOUR_MINUTE_MERIDIEM);
    }

    return formatInTimeZone(time, 'Asia/Kolkata', DATE_FORMATS.DATE_TIME_SHORT_MONTH);
  }

  render() {
    const cardAttributeTemplate = get(
      this.props.card,
      ['metadata', 'attributes', 'template', 0],
      ''
    );
    const cardAttributeTicker = get(this.props.card, ['metadata', 'attributes', 'ticker'], []);
    const cardTitle = StoryCard.getCardTitle(
      this.props.card['story-elements'],
      this.props.story.headline
    );
    const classes = cardAttributeTemplate ? `story-card-${cardAttributeTemplate}` : '';
    const buttonClass = this.state.expanded ? 'expanded' : '';

    const isLiveBlog = get(this.props, ['story', 'story-template']) === 'live-blog';

    if (!this.props.pinned) {
      return (
        <TrackVisibility>
          <div
            styleName="story-card"
            className={`${classes} print-story-card`}
            id={`card-${this.props.card.id}`}
          >
            <div styleName="story-card-text-elements">
              <div styleName="card-updated-time">
                {isToday(new Date(this.props.card['card-added-at'])) ? (
                  <time dateTime={this.getCardTime()}>
                    <span>{this.getCardTime()}</span>
                  </time>
                ) : (
                  <>
                    <time dateTime={this.getCardTime()}>
                      <span>{this.getCardTime().split(', ')[1]}</span>
                      <span>{this.getCardTime().split(', ')[0]}</span>
                    </time>
                  </>
                )}
              </div>
              <div styleName="share-button-wrapper">
                <MediaQuery maxWidth={1199}>
                  <Share
                    title={cardTitle}
                    url={this.props.story.slug}
                    summary={this.props.story.summary}
                    card={this.props.card}
                    services={['whatsapp', 'twitter', 'facebook', 'linkedin', 'telegram']}
                    shareStyle="blue"
                  />
                </MediaQuery>
                <MediaQuery minWidth={1200}>
                  <Share
                    title={cardTitle}
                    url={this.props.story.slug}
                    summary={this.props.story.summary}
                    card={this.props.card}
                    services={['whatsapp', 'twitter', 'facebook', 'linkedin', 'telegram']}
                    servicesToShowByDefault={2}
                    showToggleWhenExpanded
                  />
                </MediaQuery>
              </div>
              {this.props.cardIndex >= this.props.expandedCards && (
                <button
                  styleName={`expand-collapse-button ${buttonClass}`}
                  onClick={this.expandCollapseHandler}
                />
              )}
              {this.elements(
                this.props.card,
                this.props.card['story-elements'],
                this.props.story,
                cardAttributeTemplate,
                cardAttributeTicker
              )}
            </div>
            {isLiveBlog && this.props.cardIndex === 0 && (
              <>
                <div className="responsive-ad content-center mobile-only">
                  <AdWithPlaceholder
                    adtype="LiveBlogAfterOneCardMobile"
                    height="280px"
                    width="336px"
                    adUnit="Mid_300x250_1"
                    sizes={[
                      [300, 250],
                      [336, 280],
                    ]}
                  />
                </div>
                <div className="responsive-ad content-center desktop-only">
                  <AdWithPlaceholder
                    adtype="LiveBlogAfterOneCardDT"
                    width="728px"
                    height="90px"
                    adUnit="Mid_728x90_1"
                    sizes={[[728, 90]]}
                  />
                </div>
              </>
            )}
            {isLiveBlog && this.props.cardIndex === 2 && (
              <>
                <div className="responsive-ad content-center mobile-only">
                  <AdWithPlaceholder
                    adtype="LiveBlogAfterThreeCardsMobile"
                    height="280px"
                    width="336px"
                    adUnit="Mid_300x250_2"
                    sizes={[
                      [300, 250],
                      [336, 280],
                    ]}
                  />
                </div>
                <div className="responsive-ad content-center desktop-only">
                  <AdWithPlaceholder
                    adtype="LiveBlogAfterThreeCardsDT"
                    width="728px"
                    height="90px"
                    adUnit="Mid_728x90_2"
                    sizes={[[728, 90]]}
                  />
                </div>
              </>
            )}
            {isLiveBlog && isMobile && this.props.cardIndex === 4 && (
              <>
                <div className="responsive-ad content-center mobile-only">
                  <AdWithPlaceholder
                    adtype="LiveBlogAfterFiveCardsMobile"
                    height="280px"
                    width="336px"
                    adUnit="Mid_300x250_3"
                    sizes={[
                      [300, 250],
                      [336, 280],
                    ]}
                  />
                </div>
              </>
            )}
            <br />
          </div>
        </TrackVisibility>
      );
    }

    return (
      <TrackVisibility>
        <div
          styleName="story-card pinned-story-card"
          className={`${classes} print-story-card`}
          id={`card-${this.props.card.id}`}
        >
          <div styleName="card-updated-time">
            {isToday(new Date(this.props.card['card-added-at'])) ? (
              <time dateTime={this.getCardTime()}>
                <span>{this.getCardTime()}</span>
              </time>
            ) : (
              <>
                <time dateTime={this.getCardTime()}>
                  <span>{this.getCardTime().split(', ')[1]}</span>
                  <span>{this.getCardTime().split(', ')[0]}</span>
                </time>
              </>
            )}
          </div>
          <div styleName="share-button-wrapper">
            <MediaQuery maxWidth={1199}>
              <Share
                title={cardTitle}
                url={this.props.story.slug}
                summary={this.props.story.summary}
                card={this.props.card}
                services={['whatsapp', 'twitter', 'facebook', 'linkedin', 'telegram', 'copy']}
                shareStyle="blue"
              />
            </MediaQuery>
            <MediaQuery minWidth={1200}>
              <Share
                title={cardTitle}
                url={this.props.story.slug}
                summary={this.props.story.summary}
                card={this.props.card}
                services={['whatsapp', 'twitter', 'facebook', 'linkedin', 'telegram', 'copy']}
                servicesToShowByDefault={2}
                showToggleWhenExpanded
              />
            </MediaQuery>
          </div>
          {this.props.cardIndex >= this.props.expandedCards && (
            <button
              styleName={`expand-collapse-button ${buttonClass}`}
              onClick={this.expandCollapseHandler}
            />
          )}
          {this.elements(
            this.props.card,
            this.props.card['story-elements'],
            this.props.story,
            cardAttributeTemplate,
            cardAttributeTicker
          )}
        </div>
      </TrackVisibility>
    );
  }
}

StoryCard.defaultProps = {
  pinned: false,
  sharedCardId: '',
};

StoryCard.propTypes = {
  card: objectOf(any).isRequired,
  story: objectOf(any).isRequired,
  sharedCardId: string,
  cardIndex: number.isRequired,
  expandedCards: number.isRequired,
  order: string.isRequired,
  cardHighlighted: string.isRequired,
  removeKeyHighlight: func.isRequired,
  pinned: bool,
  unsetSharedCardId: func,
};

function mapStateToProps(state) {
  return {
    cardHighlighted: state.cardHighlighted,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    removeKeyHighlight: () => {
      dispatch({
        type: REMOVE_CLICK_KEY_HIGHLIGHT,
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StoryCard);
