import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './stock-notifications.m.css';
import { connect } from 'react-redux';
import { BQ_STOCK_NOTIFICATIONS_DISABLE } from '../../../../store/actions';

const Notification = ({
  message,
  stockStatus,
  failure = null,
  closeNotification,
  undoRemoval,
  deleteStocksPermanently,
}) => {
  const notificationRef = useRef();
  const showIcon = () => {
    if (stockStatus === 'Add') {
      return <div className="icon-ic_binoculars" />;
    } else if (stockStatus === 'alreadyAdded') {
      return (
        <span className="icon-ic_info_outline">
          <span className="i-icon">i</span>
        </span>
      );
    } else if (failure || stockStatus === 'LimitExceed') {
      return <div className="icon-ic_warning" />;
    }
  };
  const hideIcon =
    stockStatus === 'Remove' || stockStatus === 'setAlarm' || stockStatus === 'setAlert';

  return (
    <div ref={notificationRef} styleName="popupContainer">
      {hideIcon ? null : <div>{showIcon()}</div>}
      <div>
        {message.map((m, id) =>
          stockStatus === 'Remove' ? (
            <p key={id} styleName="message-paragraph">
              {`${m}.`}
              <a onClick={undoRemoval}>
                <b>UNDO</b>
              </a>
            </p>
          ) : (
            <p key={id} styleName="message-paragraph" dangerouslySetInnerHTML={{ __html: m }} />
          ),
        )}
      </div>
    </div>
  );
};

Notification.propTypes = {
  message: PropTypes.arrayOf(PropTypes.string),
  stockStatus: PropTypes.string,
  failure: PropTypes.bool,
  undoRemoval: PropTypes.func,
  closeNotification: PropTypes.func,
  deleteStocksPermanently: PropTypes.func,
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeNotification: function () {
      dispatch({ type: BQ_STOCK_NOTIFICATIONS_DISABLE });
    },
  };
};

export default connect(null, mapDispatchToProps)(Notification);
