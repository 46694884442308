import React from 'react';
import PropTypes from 'prop-types';
import VideoCardImageHeadlineAuthor from '../video-card-image-headline-author';
import ViewAll from '../../atoms/view-all';
import VideoTitle from '../titles/videos';
import { sectionNameDataLayer } from '../../../helpers/datalayer-utils';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import './videos-widget.m.css';

const VideosWidget = ({ stories }) => {
  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));

  return stories?.length > 0 ? (
    <div styleName="videos-wrapper">
      <div styleName="videos-title" className="title-with-view-all" onClick={() => sectionNameDataLayer("videos", userData, pageType)}>
        <VideoTitle />
        <ViewAll isTitle={true} />
      </div>

      <div styleName="videos">
        {stories?.map((item, index) => (
          <VideoCardImageHeadlineAuthor key={index} story={item} />
        ))}
      </div>
    </div>
  ) : null;
};

VideosWidget.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.array),
};

export default VideosWidget;
