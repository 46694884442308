import React from 'react';
import MediaQuery from 'react-responsive';
import { ResponsiveImage } from '@quintype/components';

import './snapshot-hero-image.module.css';

const SnapshotHeroImage = ({ src, ...props }) => (
  <figure styleName="image-container" {...props}>
    {src ? (
      <img src={src} width="100%" height="100%" />
    ) : (
      <>
        <MediaQuery maxWidth={699}>
          <ResponsiveImage
            {...props}
            aspectRatio={[1, 1]}
            defaultWidth={152}
            widths={[250, 480, 640]}
            sizes="(max-width: 500px) 98%, (max-width: 768px) 48%, 23%"
            imgParams={{}}
            styleName="image"
          />
        </MediaQuery>

        <MediaQuery minWidth={700}>
          <ResponsiveImage
            {...props}
            aspectRatio={[25, 8]}
            defaultWidth={500}
            widths={[250, 480, 640]}
            sizes="(max-width: 500px) 98%, (max-width: 768px) 48%, 23%"
            imgParams={{}}
            styleName="image"
          />
        </MediaQuery>
      </>
    )}
  </figure>
);

export default SnapshotHeroImage;
