import React, { useEffect, useRef } from 'react';
import { array, shape, string } from 'prop-types';
import atob from 'atob';

import Share from '../../share/share';

import './jsembed.module.css';

function cloneScriptNode(node) {
  const script = document.createElement('script');
  script.text = node.innerHTML;

  for (let i = node.attributes.length - 1; i >= 0; i--) {
    script.setAttribute(node.attributes[i].name, node.attributes[i].value);
  }

  return script;
}

function replaceScriptNodes(node) {
  if (node.tagName === 'SCRIPT') {
    node.parentNode.replaceChild(cloneScriptNode(node), node);
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  } else {
    let i = 0;
    const children = node.childNodes;
    while (i < children.length) {
      replaceScriptNodes(children[i++]);
    }
  }
}

const JSEmbed = ({ element, card, story }) => {
  const jsEmbed = useRef(null);

  useEffect(() => {
    replaceScriptNodes(jsEmbed.current);
  }, []);

  const shouldShowShare = () => {
    const embedjs = element['embed-js'];
    const decodedEmbedjs = embedjs && atob(embedjs);
    const matchResult = decodedEmbedjs.match('datawrapper');

    return matchResult && matchResult.length > 0;
  };

  const getEmbedJS = () => {
    const embedjs = element['embed-js'];
    if (embedjs) {
      const decodedEmbedJS = atob(embedjs)
        .replace('<script', '<script async')
        .replace(/pWidth='100%'&pHeight='100%'/g, "pWidth=100&pHeight=100");
      return decodedEmbedJS;
    }
    return null;
  };

  return (
    <div styleName="js-embed-element">
      {shouldShowShare() && (
        <div styleName="share">
          <Share
            title={card['story-elements'] || story.headline}
            url={story.slug}
            element={element}
            card={card}
            summary={story.summary}
            services={['facebook', 'twitter', 'linkedin', 'whatsapp', 'telegram', 'mail']}
            servicesToShowByDefault={2}
            showToggleWhenExpanded={false}
            encode={true}
          />
        </div>
      )}

      <div
        ref={jsEmbed}
        dangerouslySetInnerHTML={{ __html: getEmbedJS() }}
        styleName="iframe-element"
      />
    </div>
  );
};

JSEmbed.propTypes = {
  card: shape({
    'story-elements': array
  }).isRequired,
  story: shape({
    headline: string,
    summary: string,
    slug: string
  }).isRequired,
  element: shape({
    id: string,
    'embed-js': string
  }).isRequired
};

export default JSEmbed;
