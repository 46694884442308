import React from 'react';
import Lottie from "lottie-light-react";
import LiveIcon from '../../molecules/card-image-headline/lottie-live-icon.json';
import Icon from '@mdi/react';
import { mdiTelevisionClassic, mdiChevronRight } from '@mdi/js';
import { BQLink } from '../../atoms/bqlink';
import { watchLiveInteraction } from '../../../helpers/datalayer-utils';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import './expand-videos-widget.m.css';

const ExpandVideosWidget = ({ sectionName }) => {
  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));
  return (
    <div styleName="expandVideosWidget">
      <div styleName="live-icon">
        <Lottie animationData={LiveIcon} loop={true} autoplay={true} />
      </div>
      <div styleName="first-row">
        <div styleName="first-row-child1">
          <img src="/images/NDTV-Profit-logo-darkmode.png" alt="ndtv|Profit" width="120px" />
          <div styleName="static-text">24/7 LIVE Stream</div>
        </div>
        <div styleName="sv-bottom" onClick={() => watchLiveInteraction(userData, pageType, sectionName)}>
          <BQLink href={`/live-tv?src=livetv_strypg`} styleName="sv-bottom-text" title="Live TV">
            <Icon path={mdiTelevisionClassic} size="20px" color="#d01e51" />
            <span styleName="sv-bottom-title">WATCH</span>
            <Icon path={mdiChevronRight} size="20px" color="#d01e51" />
          </BQLink>
        </div>
      </div>
    </div>
  );
};

export default ExpandVideosWidget;
