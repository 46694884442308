import React from 'react';
import PropTypes from 'prop-types';

import './desktop-sticky-ad.m.css';

export default function DesktopStickyAd({ reduceZIndex = false }) {
  return (
    <>
      <div className="ad-container" styleName="ad-wrapper">
        <div styleName="metered-paywall">
          <div id="piano-metered-paywall" />
        </div>
      </div>
    </>
  );
}

DesktopStickyAd.propTypes = {
  reduceZIndex: PropTypes.bool
};
