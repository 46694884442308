/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Share from './share';
import SvgIcon from '../../atoms/svg-icon';
import BookmarkShare from './bookmark-share';
import { shareIconDL } from '../../../helpers/datalayer-utils';
import './share-floating-expanded.m.css';

const ShareFloatingExpanded = ({ story, publisherUrl, bookmark }) => {
  const userData = useSelector((state) => get(state, ['userData']));

  const independence = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'independence_day'], false)
  );

  const getDisplayName = () => {
    const section = story?.sections?.find(sec => 'display-name' in sec);
    return section ? section['display-name'] : '';
  };

  const Template = ({ services, socialShareConfig }) => {
    const onClickService = (event, service) => {
      if (socialShareConfig[service].func) {
        socialShareConfig[service].func(event);
      }
      shareIconDL(service, story, userData, 'story floating toolbar');
    };

    return (
      <ul styleName="social-list">
        {services?.map((service, index) => (
          socialShareConfig[service] ? (
            <li key={index}>
              <a
                target="_blank"
                data-social-media-type={service}
                href={socialShareConfig[service]?.url}
                rel="noopener noreferrer nofollow"
                data-social-media-share-url={socialShareConfig[service]?.url}
                data-component-type="share"
                data-track-click={service}
                className={`icon-ic_${service}`}
                onClick={(event) => onClickService(event, service)}
              ></a>
              <span styleName="social-title">
                {socialShareConfig[service].title === 'Twitter'
                  ? 'X (was Twitter)'
                  : socialShareConfig[service].title}
              </span>
            </li>
          ) : (
            <li key={index}>
              <span styleName="social-title">{service} not available</span>
            </li>
          )
        ))}
      </ul>
    );
  };

  return (
    <div styleName={`share-floating-expanded show ${independence?.active ? 'increase-bar' : ''}`}>
      <div styleName="sidebar" className="container">
        <div styleName="breadcrumb" className="desktop-only">
          <span>{getDisplayName()}</span>
          <span styleName="dot"></span>
          <span styleName="breadcrumb-headline">{get(story, ['headline'], '')}</span>
        </div>
        <div styleName="shareIcon-mobile" className="mobile-only">
          <SvgIcon type="share-icon-red" source="image" />
          <span>Share</span>
        </div>
        <div styleName="share-wrapper">
          <div styleName="share-wrapper-ctx">
            <Share
              template={Template}
              expanded={true}
              alignType="horizontal"
              showToggleWhenExpanded={false}
              publisherUrl={publisherUrl}
              url={story.slug}
              title={story.headline}
              services={['whatsapp', 'twitter', 'facebook', 'telegram', 'copy']}
            />
            {bookmark && (
              <div styleName="bookmark-floating">
                <BookmarkShare />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ShareFloatingExpanded.propTypes = {
  story: PropTypes.shape({
    sections: PropTypes.array,
    slug: PropTypes.string,
    headline: PropTypes.string,
  }).isRequired,
  publisherUrl: PropTypes.string.isRequired,
  bookmark: PropTypes.bool,
  socialShareConfig: PropTypes.object.isRequired,
};

export default ShareFloatingExpanded;
