import Lottie from 'lottie-light-react';
import React, { useEffect, useState } from 'react';
import LockIcon from './lock.json';
import { mdiApple, mdiCellphone, mdiEmail } from '@mdi/js';
import Icon from '@mdi/react';
import get from 'lodash/get';
import { connect, useSelector } from 'react-redux';
import { signInClick } from '../../../helpers/datalayer-utils';
import { getSSOLoginUrl, getSSORedirectUrl } from '../../../helpers/subscription-helper';
import { BQLink } from '../../atoms/bqlink';
import './reg-paywall.m.css';

const RegPaywall = ({ integrations }) => {
  const [redirectUrl, setRedirectUrl] = useState('');
  const [loginUrl, setLoginUrl] = useState('');
  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));

  useEffect(() => {
    const url = new URL(window?.location?.href);
    getSSORedirectUrl(url).then((pageUrl) => {
      setRedirectUrl(pageUrl);
    });
  }, []);

  useEffect(() => {
    getSSOLoginUrl(
      integrations?.sso?.client_id,
      integrations?.sso?.client_secret,
      redirectUrl,
      SSO_LOGIN_URL,
    ).then((pageUrl) => {
      setLoginUrl(pageUrl);
    });
  }, [redirectUrl]);

  const SSO_LOGIN_URL = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'subscription', 'login_url'], null),
  );

  const SSO_GOOGLE_LOGIN_URL = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'subscription', 'google_login'], null)
  );

  return (
    <div styleName="reg-paywall-ctx">
      <div styleName="top-gradient" />
      <img src="/images/story_top_img.svg" styleName="top-banner" />
      <div styleName="paywall-ctx">
        <div styleName="left-side">
          <div styleName="lock-icon">
            <Lottie animationData={LockIcon} loop={true} autoplay={true} styleName="custom-lock" />
          </div>
          <div styleName="lower-part">
            <div styleName="title">Register for Free</div>
            <div styleName="description">to continue reading</div>
            <div styleName="logins">
              <div onClick={() => signInClick(userData, pageType, 'Sign Up with Google', 'registration wall')}>
                <BQLink href={loginUrl}>
                  <div styleName="google-sign-in">
                    <span styleName="google-icon" />
                    <span styleName="text">Sign Up with Google</span>
                  </div>
                </BQLink>
              </div>
              <div styleName="or-text-ctx">
                <div styleName="left-line" />
                <span styleName="text">OR</span>
                <div styleName="right-line" />
              </div>
              <div styleName="other-sign-in">
                <div onClick={() => signInClick(userData, pageType, 'email', 'registration wall')}>
                  <BQLink href={loginUrl}>
                    <div styleName="round-icon">
                      <Icon path={mdiEmail} size="20px" color="#2B2B2B" />
                    </div>
                  </BQLink>
                </div>
                <div onClick={() => signInClick(userData, pageType, 'apple', 'registration wall')}>
                  <BQLink href={loginUrl}>
                    <div styleName="round-icon apple">
                      <Icon path={mdiApple} size="22px" color="#2B2B2B" />
                    </div>
                  </BQLink>
                </div>
                <div onClick={() => signInClick(userData, pageType, 'mobile number', 'registration wall')}>
                  <BQLink href={loginUrl}>
                    <div styleName="round-icon phone">
                      <Icon path={mdiCellphone} size="18px" color="#2B2B2B" />
                    </div>
                  </BQLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div styleName="right-side"></div>
      </div>
      <div styleName="sub-message-footer">
        <span styleName="sub-message">I have an Account</span>
        <div onClick={() => signInClick(userData, pageType, 'Sign In', 'registration wall')}>
          <BQLink href={loginUrl}>
            <span styleName="sign-in">&nbsp;Sign In</span>
          </BQLink>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  integrations: state.qt.config['integrations'],
});

export default connect(mapStateToProps)(RegPaywall);
