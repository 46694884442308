/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { string, any, objectOf, func, object, bool, element } from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import CardImage from '../atoms/cardimage';
import Footer from '../story/footer/footer';
import Sidebar from '../story/sidebar/sidebar';
import Authors from '../story/authors/authors';
import PublishedInfo from '../story/published-info/published-info';
import Share from '../story/share/share';
import ShareFloatingExpanded from '../story/share/share-floating-expanded';
import AdWithPlaceholder from '../molecules/ad-with-placeholder';
import {
  syndicatedSource,
  checkCookie,
  getStoryDate,
  checkGeoBlock,
  formatPublishedDate,
  getDDMMYYYY,
  getUserStatus,
} from '../../helpers/utils';
import { shareIconDL, trackUserInteraction } from '../../helpers/datalayer-utils';
import { WithLazy } from '@quintype/components';
import BreadcrumbBasic from '../atoms/breadcrumb-basic';
import TrackVisibility from '../track-visibility';
import VideosWidget from '../molecules/videos-widget';
import MailmodoNewsLetter from '../molecules/newsletter/mailmodo-news-letter';
import LatestNewsWidget from '../story/latest-news-widget';
import { KeyHighlights } from '../molecules/key-highlight';
import { ClockOutlineIcon } from '../atoms/material-icons';
import ContentNotAvailable from '../story/contentnotavailable/contentnotavailable';
import ExpandVideosWidget from '../story/expand-videos-widget/expand-videos-widget';
import BudgetStrip2023 from '../collection-templates/budget-strip-2023';
import HardPaywall from '../story/story-paywall/hard-paywall';
import { isDesktop } from 'react-device-detect';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { getSSORedirectUrl } from '../../helpers/subscription-helper';
import RegPaywall from '../story/story-paywall/reg-paywall';
import throttle from 'lodash/throttle';
import VideosList from '../../json/story-test-page/videos.json';
import Icon from '@mdi/react';
import { mdiShare } from '@mdi/js';
import BookmarkShare from '../story/share/bookmark-share';
import './story-base-template.m.css';

const storyShareClickHandler = (storyContentId, storyUrl) => (event) =>
  global.app.registerStoryShare(
    storyContentId,
    event.currentTarget.getAttribute('data-social-media-type'),
    storyUrl,
  );

function addLinkToCopy() {
  var bodyElement = document.getElementsByTagName('body')[0];
  var selection = window.getSelection();
  var pagelink =
    "<br /><br /> Read more at: <a href='" +
    document.location.href +
    "'>" +
    document.location.href +
    '</a><br />Copyright &copy; NDTV Profit';
  var copytext = selection.toString().substring(0, 400) + pagelink;
  var newdiv = document.createElement('div');
  newdiv.style.position = 'absolute';
  newdiv.style.left = '-99999px';
  bodyElement.appendChild(newdiv);
  newdiv.innerHTML = copytext;
  selection.selectAllChildren(newdiv);
  window.setTimeout(function () {
    bodyElement.removeChild(newdiv);
  }, 0);
}

const StoryBaseTemplate = (props) => {
  const test_page = props?.story?.test_page;
  const [isGeoBlock, setIsGeoBlock] = useState(false);
  const sectionSlug = get(props, ['story', 'sections']).map((item) => item.slug);
  const [showFloatingSocial, setShowFloatingSocial] = useState(false);
  const [oneTapDisable, setOneTapDisable] = useState(true);
  const isSubscriber = useSelector((state) => get(state, ['userData', 'isSubscriber'], false));
  const isLoggedIn = useSelector((state) => get(state, ['userData', 'isLoggedIn'], false));
  const SSO_ONE_TAP_URL = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'subscription', 'google_one_tap_url'], null),
  );
  const SSO = useSelector((state) => get(state, ['qt', 'config', 'integrations', 'sso'], null));
  const userData = useSelector((state) => get(state, ['userData']));
  const newsletter = props?.newsLetterList?.find(n => n.data_key === 'Talking');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const timeoutRef = useRef(null);
  const shareContainerRef = useRef(null);
  const restoreScrollAfterLazyLoad = () => {
    let lastScrollY = window.scrollY;

    const observer = new MutationObserver(() => {
      window.scrollTo(0, lastScrollY);
    });

    observer.observe(document.body, { childList: true, subtree: true });

    window.addEventListener('scroll', () => {
      lastScrollY = window.scrollY;
    });

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', () => {
        lastScrollY = window.scrollY;
      });
    };
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (shareContainerRef.current && !shareContainerRef.current.contains(event.target)) {
        setIsShareModalOpen(false);
      }
    };

    const handleScroll = () => {
      setIsShareModalOpen(false);
    };

    if (isShareModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('scroll', handleScroll);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isShareModalOpen]);

  useEffect(restoreScrollAfterLazyLoad, []);
  useEffect(() => {
    document.oncopy = addLinkToCopy;
    const queryObject = queryString.parse(window.location.search);
    if (queryObject.utm_source === 'wru') {
      const datalayerFields = {
        category: 'article page',
        action: 'Recommended for you',
        label: get(props, ['story', 'headline'], ''),
        event: 'event articlepage',
        cd22: 'link',
        cd23: 'bottom more on this topic',
        cd24: 'NA',
        cd26: syndicatedSource(get(props, 'story', null)),
        cd31: get(props, ['story', 'story-template'], 'text'),
        cd35: 'wru',
        cd34: 'NA',
      };
      trackUserInteraction(datalayerFields);
    }
    if (window.history.length === 1) {
      const _datalayerFields = {
        event: 'event Back button clicked',
        label: 'back button',
        action: 'click',
      };
      (function (window, location) {
        history.replaceState(null, document.title, location.pathname + '#!/homepage');
        history.pushState(null, document.title, location.pathname);

        window.addEventListener(
          'popstate',
          function () {
            if (location.hash === '#!/homepage') {
              history.replaceState(null, document.title, location.pathname);
              trackUserInteraction(_datalayerFields);
              setTimeout(function () {
                location.replace('https://www.ndtvprofit.com/');
              }, 0);
            }
          },
          false,
        );
      })(window, location);
    }
    const GeoTag = checkCookie('GeoTag');
    const GeoBlock = checkGeoBlock(GeoTag, props?.story);
    setIsGeoBlock(GeoBlock);

    const bookmarkMetadata = {
      'story-template': props?.story?.['story-template'],
      updatedAt: props?.story?.['updated-at'],
    };
    if (window.location.pathname.includes('research-reports')) {
      bookmarkMetadata.authorName = props?.story?.['author-name'];
    }
    window.localStorage.setItem('storyInfo', JSON.stringify(bookmarkMetadata));
  }, []);

  const textSelection = !props.textSelectionEnabled ? 'disable-selection' : '';
  const {
    id = 'NA',
    'author-name': authorName = 'NA',
    'last-published-at': lastPublishedAt,
    'updated-at': updatedAt,
  } = props.story;
  const finalUpdatedAt = lastPublishedAt || updatedAt || 'NA';
  let articlepara = 'NA';

  if (typeof window !== 'undefined') {
    const paragraphs = window.document.getElementsByTagName('p');
    articlepara = paragraphs?.length || 'NA';
  }
  const publishedAt = get(
    props.story,
    'first-published-at',
    get(props.story, 'published-at', 'NA'),
  );
  const publishedDateTime = publishedAt !== 'NA' ? formatPublishedDate(publishedAt) : 'NA, NA';
  const [time, date] = publishedDateTime?.split(',');
  const publishedTime = time?.trim() || 'NA';
  const publishedDate = getDDMMYYYY(publishedAt) || 'NA';
  const updatedAtDateTime = getDDMMYYYY(finalUpdatedAt) || 'NA';
  const storyTemplate = get(props.story, ['story-template'], 'NA');
  const slug = get(props.story, ['slug'], 'NA');
  const sectionName = slug !== 'NA' ? slug.split('/')[0] : 'NA';
  const contentType =
    storyTemplate === 'video' || storyTemplate === 'ndtv-video' ? 'video' : 'article';
  const source = get(
    props.story,
    ['metadata', 'story-attributes', 'syndicatedfrom', '0'],
    'NDTV Profit',
  );

  const authors = authorName;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const datalayerFields = {
    page_title: props?.story?.headline || 'NA',
    ContentType: contentType,
    page_template: 'story',
    section_name: 'story-header',
    authors: authors || 'NA',
    agency: source || 'NA',
    category: sectionName || 'NA',
    publish_date: publishedDate || 'NA',
    publish_time: publishedTime || 'NA',
    last_update_date: updatedAtDateTime || 'NA',
    tvc_article_id: id || 'NA',
    sub_category: 'NA',
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: getUserStatus(),
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
  };

  const getHeroImageCaption = () => {
    let heroImageCaption =
      get(props, ['story', 'hero-image-caption'], null) ||
      get(
        props,
        ['story', 'alternative', 'home', 'default', 'hero-image', 'hero-image-caption'],
        '',
      );

    heroImageCaption =
      heroImageCaption &&
      heroImageCaption
        .replace(/<div class="paragraphs"><p>/gi, '')
        .split('</p></div>')
        .join(' ')
        .split('<br>')
        .join(' ');

    return heroImageCaption ? (
      <div styleName="hero-image-caption" dangerouslySetInnerHTML={{ __html: heroImageCaption }} />
    ) : (
      <></>
    );
  };

  const PublisherAndSocialShareSection = () => {
    const handleShareClick = () => {
      if (isMobile) {
        if (navigator.share) {
          navigator.share({
            title: props?.story?.title || document?.title || '',
            text: props?.story?.summary || '',
            url: `${props?.publisherUrl}/${props?.story?.slug}` || '#'
          })
          .catch(error => console.log('Error sharing:', error));
        } else {
          setIsShareModalOpen(!isShareModalOpen);
        }
      } else {
        setIsShareModalOpen(!isShareModalOpen);
      }
    };

    return (
      <div>
        <div styleName="story-content-head-first-fold"></div>
        <div styleName="story-content-head">
          <div styleName="author-and-published-time">
            <Authors
              storyId={props.story.id}
              authors={props.story.authors}
              datalayerFields={datalayerFields}
              alt="BQ"
            />
            <span
              styleName="story-published-date"
              dateTime={new Date(props.story['first-published-at'])
                .toISOString()
                .replace('Z', '+05:30')}
            >
              <ClockOutlineIcon size="18px" color="var(--fourth-color)" />
              <span styleName="story-date">{getStoryDate(props.story['last-published-at'])} </span>
              <span className="icon-ic_info_outline" styleName="story-date-info-icon">
                <span className="i-icon">i</span>
              </span>
              <div styleName="popover-story-dates">
                <PublishedInfo
                  publishedAt={props.story['first-published-at']}
                  updatedAt={props.story['last-published-at']}
                />
              </div>
            </span>
          </div>
          <div styleName="share-floating-expanded" ref={shareContainerRef} >
            <div
              styleName="g-news"
              onClick={() => {
                window.open(
                  'https://news.google.com/publications/CAAqBwgKMKr0kAsw9JylAw?hl=en-IN&gl=IN&ceid=IN%3Aen',
                  '_blank',
                ),
                  shareIconDL('Google News', props?.story, userData, 'story header');
              }}
            >
              <i styleName="icon" className="icon-ic_google-news" />
            </div>
            <div styleName="icon-wrapper-bookmark">
              <BookmarkShare />
            </div>
            <div styleName="share-btn icon"
              onClick={() => handleShareClick()}
              onMouseEnter={() => setIsShareModalOpen(true)}
            >
              <Icon path={mdiShare} size='24px' />
            </div>
            {isShareModalOpen && !isMobile && (
              <Share
                datalayerFields={datalayerFields}
                url={props.story.slug}
                summary={props.story.summary}
                services={['whatsapp', 'twitter', 'facebook', 'telegram']}
                onClick={storyShareClickHandler(
                  props.story['story-content-id'],
                  `${props.publisherUrl}/${props.story.slug}`,
                )}
                source="story-content-head"
                expanded={true}
                alignType="horizontal"
                showToggleWhenExpanded={false}
                bookmark={false}
                isModal={true}
              />
            )}
          </div>

          {showFloatingSocial ? (
            <ShareFloatingExpanded
              datalayerFields={datalayerFields}
              title={props.story.headline}
              url={props.story.slug}
              summary={props.story.summary}
              services={['whatsapp', 'twitter', 'facebook', 'telegram', 'copy']}
              onClick={storyShareClickHandler(
                props.story['story-content-id'],
                `${props.publisherUrl}/${props.story.slug}`,
              )}
              storyId={get(props, ['story', 'id'])}
              bookmark={props.bookmark}
              story={props.story}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const isBudgetStory =
    get(props, ['story', 'sections'])
      .map((item) => item.slug)
      .includes('budget') ||
    get(props, ['story', 'sections'])
      .map((item) => item.slug)
      .includes('budget-2023') ||
    get(props, ['story', 'sections'])
      .map((item) => item.slug)
      .includes('budget-2024') ||
    get(props, ['story', 'sections'])
      .map((item) => item.slug)
      .includes('budget-2025');
  const isBrandStudioStory = get(props, ['story', 'author-name']) === 'NDTV Profit Brand Studio';
  const isLiveBlog = get(props, ['story', 'story-template']) === 'live-blog';
  const isVideoStory = get(props, ['story', 'story-template']) === 'video';
  const noHeroImage =
    get(props, ['story', 'hero-image-s3-key']) === null &&
    !isEmpty(get(props, ['story', 'alternative'])) &&
    !!get(props, ['story', 'alternative', 'home', 'default', 'hero-image', 'hero-image-s3-key']);

  const renderAdBelowHeroImage = () => (
    <div
      styleName="BelowHeroImage-ad"
      className="content-center mobile-only"
      style={{ minHeight: '280px' }}
    >
      {isMobile && (
        <AdWithPlaceholder
          adtype="RightSidebar"
          height="280px"
          width="336px"
          adUnit="Top_300x250"
          sizes={[
            [300, 250],
            [336, 280],
          ]}
        />
      )}
    </div>
  );

  const renderLiveBlogAdBelowHeroImage = () => (
    <>
      <div
        styleName="BelowHeroImage-ad"
        className="content-center mobile-only"
        style={{ minHeight: '280px' }}
      >
        <AdWithPlaceholder
          adtype="BelowHeroImage-ad"
          height="280px"
          width="336px"
          adUnit="Top_300x250"
          sizes={[
            [300, 250],
            [336, 280],
          ]}
        />
      </div>
      <div className="responsive-ad content-center desktop-only">
        <AdWithPlaceholder
          adtype="BelowHeroImage-ad"
          height="90px"
          width="970px"
          adUnit="Top_728x90"
          sizes={[
            [728, 90],
            [970, 90],
          ]}
        />
      </div>
    </>
  );

  function loadTaboolaScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
    window._taboola = window._taboola || [];
    _taboola.push({
      mode: 'thumbnails-base-mode',
      container: 'taboola-below-article-thumbnails',
      placement: 'Below Article Thumbnails',
      target_type: 'mix'
    });
    window._taboola.push({flush: true});
  `;
    document.body.appendChild(script);
  }

  useEffect(() => {
    const loadTaboolaOnScroll = () => {
      loadTaboolaScript();
      window.removeEventListener('scroll', loadTaboolaOnScroll);
    };

    window.addEventListener('scroll', loadTaboolaOnScroll);

    return () => {
      window.removeEventListener('scroll', loadTaboolaOnScroll);
    };
  }, []);

  const [stories, setStories] = useState([]);
  useEffect(() => {
    if(test_page){
      setStories(VideosList);
    } else {
      fetch(`/api/v1/collections/must-watch-videos?limit=4`)
      .then((res) => res.json())
      .then((res) => {
        setStories(res.items.map((item) => item.story));
      });
    }
  }, []);

  useEffect(() => {
    const temp = setTimeout(() => setOneTapDisable(false), 5000);
    return () => clearTimeout(temp);
  }, []);

  // const hideShowGooglePrompt = () => {
  //   if (typeof window !== 'undefined') {
  //     const hideOneTap = window?.document !== undefined && window?.document?.cookie
  //     .split(";")
  //     .find((c) => c.indexOf("hideOneTap") > -1);
  //     if (!!hideOneTap || oneTapDisable) {
  //       return true;
  //     } else {
  //       return !!isLoggedIn;
  //     }
  //   }
  // };

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      if (credentialResponse.credential) {
        localStorage.setItem('loginMethod', 'google one tap');
        localStorage.setItem('loginAccessType', 'signin');
        getSSORedirectUrl(new URL(window?.location?.href)).then((redirectUrl) => {
          window.location.href = `${SSO_ONE_TAP_URL}?credential=${credentialResponse.credential}&clientId=${SSO.client_id}&clientSecret=${SSO.client_secret}&clientRedirecturl=${redirectUrl}`;
        });
      } else {
        console.log('Unable to receive credential');
      }
    },
    onError: () => {
      console.log('Google one tap Login Failed');
    },
    cancel_on_tap_outside: true,
    disabled: !!isLoggedIn,
    use_fedcm_for_prompt: true,
  });

  let scrollPercentage = 0;
  const taboolaRef = useRef(null);

  const hasScrolled25Ref = useRef(false);
  const hasScrolled50Ref = useRef(false);
  const hasScrolled75Ref = useRef(false);
  const hasScrolled100Ref = useRef(false);

  const calculateScrollPercentage = () => {
    if (typeof window !== 'undefined') {
      const scrollTop = window.scrollY;
      const skipDiv = taboolaRef.current;

      const documentHeight = skipDiv
        ? document.documentElement.scrollHeight - skipDiv.offsetHeight - window.innerHeight
        : document.documentElement.scrollHeight - window.innerHeight;

      if (documentHeight > 0) {
        scrollPercentage = (scrollTop / documentHeight) * 100;
        localStorage.setItem('scrollPercentage', scrollPercentage);
      } else {
        scrollPercentage = 0;
        localStorage.setItem('scrollPercentage', scrollPercentage);
      }

      checkScrollMilestones();
    }
  };

  const checkScrollMilestones = () => {
    if (scrollPercentage >= 25 && !hasScrolled25Ref.current) {
      pushScrollDataToDataLayer(25);
      hasScrolled25Ref.current = true;
    }
    if (scrollPercentage >= 50 && !hasScrolled50Ref.current) {
      pushScrollDataToDataLayer(50);
      hasScrolled50Ref.current = true;
    }
    if (scrollPercentage >= 75 && !hasScrolled75Ref.current) {
      pushScrollDataToDataLayer(75);
      hasScrolled75Ref.current = true;
    }
    if (scrollPercentage >= 100 && !hasScrolled100Ref.current) {
      pushScrollDataToDataLayer(100);
      hasScrolled100Ref.current = true;
    }
  };

  const pushScrollDataToDataLayer = (scrollMilestone) => {
    if (window.dataLayer) {
      const baseData = {
        event: 'page_scroll',
        scroll_percent: scrollMilestone + '%' || 'NA',
        page_template: 'story',
        user_id: userData?.userProfile?.userId || 'NA',
        user_status: getUserStatus(),
        login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
        language_hit: 'english',
        Platform: isMobile ? 'mobile' : 'desktop',
        SiteName: 'https://www.ndtvprofit.com',
      };

      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };

  const observeTaboolaDiv = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        calculateScrollPercentage();
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observeTaboolaDiv, {
      threshold: 0.1,
    });

    if (taboolaRef.current) {
      observer.observe(taboolaRef.current);
    }

    return () => {
      if (taboolaRef.current) {
        observer.unobserve(taboolaRef.current);
      }
    };
  }, [taboolaRef]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Throttle scroll event to avoid too frequent calculations
      const throttledScrollHandler = throttle(() => {
        calculateScrollPercentage();
      }, 300);

      window.addEventListener('scroll', throttledScrollHandler);

      return () => {
        window.removeEventListener('scroll', throttledScrollHandler);
      };
    }
  }, []);

  return (
    <>
      <BreadcrumbBasic story={props?.story} />
      <article id={`story-${props.story.id}`} className="story-page container">
        {!isGeoBlock && (
          <>
            <div className="desktop-only">
              {isDesktop && (
                <div styleName="add-with-grey-background">
                  <div className="responsive-ad content-center">
                    <AdWithPlaceholder
                      adtype="AboveArticleHeadline"
                      height="90px"
                      width="970px"
                      adUnit="Top_728x90"
                      sizes={[
                        [728, 90],
                        [970, 90],
                      ]}
                    />
                  </div>
                </div>
              )}
            </div>
            {isBudgetStory && props?.budgetBanner && (
              <div styleName="story-budgetstrip-wrapper">
                <BudgetStrip2023 />
              </div>
            )}
            <div styleName="story-content" className={textSelection}>
              <div styleName="story-header-text" className="container">
                <h1 styleName="story-headline">{props.story.headline}</h1>

                <div styleName="story-subtext-wrapper">
                  <h2 styleName="story-subtext">{props.story.summary}</h2>
                </div>
                <TrackVisibility
                  onHide={() => setShowFloatingSocial(true)}
                  onVisible={() => setShowFloatingSocial(false)}
                >
                  <div className="dummy-div-for-intersection-obeserver"></div>
                </TrackVisibility>
              </div>
              <div className="container">{PublisherAndSocialShareSection()}</div>
            </div>
          </>
        )}

        <div className="row-section sidebar" styleName="story-except-headline">
          <div styleName="left-sidebar">
            <div className="reddit-card " />
            <div className="reddit-embed" />
            <canvas className="hide-element" width="1" height="1" />
            <object className="hide-element" width="1" height="1" />
            <>
              <div styleName="story-header">
                {!isGeoBlock && !noHeroImage && (
                  <div styleName="shimmer-card">
                    <div styleName="hero-image-container">
                      <CardImage
                        story={props.story}
                        aspectRatio={[16, 9]}
                        imageType="image5x2"
                        showHeroDetails={true}
                        isLazy={false}
                        fetchPriority="high"
                        eager={true}
                        isBlur={true}
                      />
                      {getHeroImageCaption()}
                    </div>
                  </div>
                )}

                {!isGeoBlock ? (
                  <div styleName={`body-container ${noHeroImage ? 'mt-neg' : ''}`}>
                    {!isLiveBlog && !isBrandStudioStory && renderAdBelowHeroImage()}
                    {!isSubscriber &&
                      isLiveBlog &&
                      !isBrandStudioStory &&
                      renderLiveBlogAdBelowHeroImage()}
                    {isLiveBlog && <KeyHighlights cards={props.story.cards} />}
                    {props.reg_paywall && !isLoggedIn ? (
                      <>
                        {props.content}
                        <RegPaywall story={props.story} />
                      </>
                    ) : props.partialAccess ? (
                      <>
                        {props.content}
                        <HardPaywall story={props.story} />
                      </>
                    ) : (
                      <div>{props.content}</div>
                    )}
                  </div>
                ) : (
                  <div>
                    <ContentNotAvailable />
                  </div>
                )}
              </div>
            </>
            {!isVideoStory && (
              <div
                style={{
                  position: 'sticky',
                  top: '100px',
                  justifyContent: 'center',
                }}
              >
                <Footer
                  {...props}
                  PublisherAndSocialShareSection={PublisherAndSocialShareSection}
                  isGeoBlock={isGeoBlock}
                />
              </div>
            )}
          </div>
          {!isMobile && (
            <div className="right-sidebar">
              {!isVideoStory && (
                <div className="content-center desktop-only">
                  {isLiveBlog ? (
                    <div styleName="above-key-highlights">
                      <AdWithPlaceholder
                        adtype="AboveKeyHighlights"
                        height="250px"
                        width="300px"
                        adUnit="Top_300x250"
                        sizes={[[300, 250]]}
                      />
                    </div>
                  ) : (
                    <div styleName="above-popular">
                      <AdWithPlaceholder
                        adtype="AbovePopular"
                        height="250px"
                        width="300px"
                        adUnit="Top_300x250"
                        sizes={[[300, 250]]}
                      />
                    </div>
                  )}
                </div>
              )}
              <WithLazy>
                {() => (
                  <Sidebar
                    datalayerFields={datalayerFields}
                    story={props.story}
                    mostRead={props.mostRead}
                    isLiveBlog={isLiveBlog}
                    videos={stories}
                  />
                )}
              </WithLazy>
            </div>
          )}

          <WithLazy>
            {() => (
              <>
                <div className="mobile-only">
                  {isMobile && (
                    <div
                      className="responsive-ad content-center mobile-only"
                      styleName="bottom300x250ad"
                    >
                      <AdWithPlaceholder
                        adtype="midMrec"
                        height="280px"
                        width="100%"
                        adUnit="Bottom_300x250"
                        sizes={[
                          [300, 250],
                          [336, 280],
                        ]}
                      />
                    </div>
                  )}
                  <MailmodoNewsLetter
                    title={newsletter?.title}
                    heading={newsletter?.subTitle}
                    footerText1="We Promise. No Spam !"
                    footerText2="View All Newsletters"
                    page="story"
                  />
                  <ExpandVideosWidget sectionName="story-footer" />
                  <VideosWidget stories={stories} />
                </div>
                <div className="desktop-only" style={{ position: 'relative' }}>
                  <MailmodoNewsLetter
                    title={newsletter?.title}
                    heading={newsletter?.subTitle}
                    footerText1="No Spam. Just great journalism in your inbox"
                    footerText2="View All Newsletters"
                    page="story"
                  />
                </div>
              </>
            )}
          </WithLazy>
          <WithLazy>
            {() => (
              <div styleName="recomended-for-you-piano">
                <LatestNewsWidget story={props.story} />
              </div>
            )}
          </WithLazy>
          {!isBrandStudioStory && (
            <div id="taboola-below-article-thumbnails" styleName="taboola"></div>
          )}
        </div>
      </article>
    </>
  );
};

StoryBaseTemplate.propTypes = {
  publisherUrl: string.isRequired,
  story: objectOf(any).isRequired,
  content: element.isRequired,
  previewContent: element.isRequired,
  partialContent: element,
  textSelectionEnabled: bool.isRequired,
  cardOrder: string,
  orderChanged: func,
  hasAccess: bool,
  member: object,
  isLoading: bool,
  updateAccess: func,
  mostRead: object,
  partialAccess: bool,
  amazon_polly: bool,
  bookmark: bool,
  reg_paywall: bool,
  budgetBanner: bool,
};

StoryBaseTemplate.defaultProps = {
  cardOrder: '',
  orderChanged: () => {},
};

const mapStateToProps = (state) => ({
  publisherUrl: state.qt.config['sketches-host'],
  textSelectionEnabled: state.qt.config.features.story.text_selection.enabled,
  amazon_polly: get(state.qt, ['config', 'features', 'amazon_polly', 'enabled'], false),
  bookmark: get(state, ['qt', 'config', 'features', 'bookmark', 'enabled'], false),
  budgetBanner: get(state.qt, ['config', 'features', 'budget', 'story-budget-banner'], false),
  newsLetterList: get(state, ['qt', 'config', 'features', 'newsLetterList'], []),
});

export default connect(mapStateToProps, {})(StoryBaseTemplate);
