import BlankStory from '../story-templates/blank';
import LiveBlog from '../story-templates/live-blog';
import VideoStory from './video';
import CardImageAuthorHeadline from '../molecules/card-image-author-headline';
import PremiumStory from '../story-templates/premium-story';

const templates = {
  'live-blog': LiveBlog,
  'story-snapshot': CardImageAuthorHeadline,
  'bq-story-snapshot': CardImageAuthorHeadline,
  video: VideoStory,
  'ndtv-video': VideoStory,
  'premium-story': PremiumStory,
};

const getTemplate = (templateName) => templates[templateName] || BlankStory;

export default getTemplate;
