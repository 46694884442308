/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { string, any, objectOf, func, object, bool, element } from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import CardImage from '../atoms/cardimage';
import Footer from '../story/footer/footer';
import Authors from '../story/authors/authors';
import PublishedInfo from '../story/published-info/published-info';
import Share from '../story/share/share';
import ShareFloatingExpanded from '../story/share/share-floating-expanded';
import { isStoryExclusive } from '../../helpers/story-helpers';
import {
  syndicatedSource,
  checkCookie,
  getStoryDate,
  getSection,
  checkGeoBlock,
  formatPublishedDate,
  getDDMMYYYY,
  getUserStatus,
} from '../../helpers/utils';
import {
  shareIconDL,
  trackUserInteraction,
  watchLiveInteraction,
} from '../../helpers/datalayer-utils';
import SvgIcon from '../atoms/svg-icon';
import BreadcrumbBasic from '../atoms/breadcrumb-basic';
import Slider from 'react-slick';
import RightArrowSmall from '../atoms/icons/right-arrow-small';
import LeftArrowSmall from '../atoms/icons/left-arrow-small';
import StoryCardVerticalWithImageDate from '../molecules/story-card/story-card-vertical-with-image-date';
import VideosWidget from '../molecules/videos-widget';
import VideoTitle from '../molecules/titles/videos';
import TrackVisibility from '../track-visibility';
import { BQLink } from '../atoms/bqlink';
import { ClockOutlineIcon } from '../atoms/material-icons';
import ContentNotAvailable from '../story/contentnotavailable/contentnotavailable';
import MailmodoNewsLetter from '../molecules/newsletter/mailmodo-news-letter';
import MoreLikeThisWidget from '../story/more-like-this-widget';
import storyElementWithCustomTemplates from '../story/story-elements';
import ExclusivesStorySliderWidget from '../collection-templates/exclusives-story-slider-widget';
import MarketBox from '../story/market-box/market-box';
import LiveTvSmallBanner from '../atoms/icons/live-tv-small-banner';
import BudgetStrip2023 from '../collection-templates/budget-strip-2023';
import PremiumStories from '../../json/story-test-page/premiumStories.json';
import Videos from '../../json/story-test-page/videos.json';
import TopIndices from '../../json/story-test-page/topIndices.json';
import Icon from '@mdi/react';
import { mdiShare } from '@mdi/js';
import BookmarkShare from '../story/share/bookmark-share';
import './premium-story.m.css';

function changeSymbolColor(element) {
  const newElement = { ...element };
  if (newElement.type === 'text') {
    newElement.text = newElement.text.replace(/♥|♦/gi, function (matched) {
      return `<span style="color:red;">${matched}</span>`;
    });
  }
  return newElement;
}

const storyShareClickHandler = (storyContentId, storyUrl) => (event) =>
  global.app.registerStoryShare(
    storyContentId,
    event.currentTarget.getAttribute('data-social-media-type'),
    storyUrl,
  );

function addLinkToCopy() {
  var bodyElement = document.getElementsByTagName('body')[0];
  var selection = window.getSelection();
  var pagelink =
    "<br /><br /> Read more at: <a href='" +
    document.location.href +
    "'>" +
    document.location.href +
    '</a><br />Copyright &copy; NDTV Profit';
  var copytext = selection.toString().substring(0, 400) + pagelink;
  var newdiv = document.createElement('div');
  newdiv.style.position = 'absolute';
  newdiv.style.left = '-99999px';
  bodyElement.appendChild(newdiv);
  newdiv.innerHTML = copytext;
  selection.selectAllChildren(newdiv);
  window.setTimeout(function () {
    bodyElement.removeChild(newdiv);
  }, 0);
}

const PremiumStory = (props) => {
  const test_page = props?.story?.test_page;
  const [isGeoBlock, setIsGeoBlock] = useState(false);
  const [topIndices, setTopIndices] = useState([]);
  const [showFloatingSocial, setShowFloatingSocial] = useState(false);
  const [premium, setPremium] = useState(null);
  const userData = useSelector((state) => get(state, ['userData']));
  const pageType = useSelector((state) => get(state, ['qt', 'pageType']));
  const newsletter = props?.newsLetterList?.find(n => n.data_key === 'Talking');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const timeoutRef = useRef(null);
  const shareContainerRef = useRef(null);

  useEffect(() => {
    document.oncopy = addLinkToCopy;
    const queryObject = queryString.parse(window.location.search);

    if (queryObject.utm_source === 'wru') {
      const datalayerFields = {
        category: 'article page',
        action: 'Recommended for you',
        label: get(props, ['story', 'headline'], ''),
        event: 'event articlepage',
        cd22: 'link',
        cd23: 'bottom more on this topic',
        cd24: 'NA',
        cd26: syndicatedSource(get(props, 'story', null)),
        cd31: get(props, ['story', 'story-template'], 'text'),
        cd35: 'wru',
        cd34: 'NA',
      };
      trackUserInteraction(datalayerFields);
    }
    if (window.history.length === 1) {
      const _datalayerFields = {
        event: 'event Back button clicked',
        label: 'back button',
        action: 'click',
      };
      (function (window, location) {
        history.replaceState(null, document.title, location.pathname + '#!/homepage');
        history.pushState(null, document.title, location.pathname);

        window.addEventListener(
          'popstate',
          function () {
            if (location.hash === '#!/homepage') {
              history.replaceState(null, document.title, location.pathname);
              trackUserInteraction(_datalayerFields);
              setTimeout(function () {
                location.replace('https://www.ndtvprofit.com/');
              }, 0);
            }
          },
          false,
        );
      })(window, location);
    }
    const GeoTag = checkCookie('GeoTag');
    const GeoBlock = checkGeoBlock(GeoTag, props?.story);
    setIsGeoBlock(GeoBlock);

    const bookmarkMetadata = {
      'story-template': props?.story?.['story-template'],
      updatedAt: props?.story?.['updated-at'],
    };
    if (window.location.pathname.includes('research-reports')) {
      bookmarkMetadata.authorName = props?.story?.['author-name'];
    }
    window.localStorage.setItem('storyInfo', JSON.stringify(bookmarkMetadata));
  }, []);

  const renderCards = (story = {}, storyCards = [], preview = false) => {
    const cardLength = storyCards.length;
    return (
      <React.Fragment>
        {storyCards.map((card, index) => {
          const cardAttributeTemplate = get(card, ['metadata', 'attributes', 'template', 0], '');
          const cardAttributeTicker = get(card, ['metadata', 'attributes', 'ticker'], []);
          const classes = cardAttributeTemplate ? `story-card-${cardAttributeTemplate}` : '';
          const storyStyleType = get(story, ['metadata', 'story-attributes', 'storystyletype', 0]);
          const storyElements = card['story-elements'];

          return (
            <React.Fragment key={card.id}>
              <div
                id={`card-${card.id}`}
                key={card.id}
                className={`${classes} paywall print-story-card`}
              >
                {cardAttributeTicker.length ? <MarketBox entities={cardAttributeTicker} /> : null}
                {storyElements.map((element, index) => {
                  const storyElement =
                    storyStyleType === 'cards' ? changeSymbolColor(element) : element;

                  return (
                    <>
                      {storyElementWithCustomTemplates(
                        card,
                        storyElement,
                        story,
                        cardAttributeTemplate,
                      )}
                    </>
                  );
                })}
              </div>
              {index === Math.round(cardLength / 2) - 1 && !preview ? <></> : ''}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const textSelection = !props.textSelectionEnabled ? 'disable-selection' : '';
  const {
    id = 'NA',
    'author-name': authorName = 'NA',
    'last-published-at': lastPublishedAt,
    'updated-at': updatedAt,
  } = props.story;
  const finalUpdatedAt = lastPublishedAt || updatedAt || 'NA';
  const publishedAt = get(
    props.story,
    'first-published-at',
    get(props.story, 'published-at', 'NA'),
  );
  const publishedDateTime = publishedAt !== 'NA' ? formatPublishedDate(publishedAt) : 'NA, NA';
  const [time, date] = publishedDateTime?.split(',');
  const publishedTime = time?.trim() || 'NA';
  const publishedDate = getDDMMYYYY(publishedAt) || 'NA';
  const updatedAtDateTime = getDDMMYYYY(finalUpdatedAt) || 'NA';
  const storyTemplate = get(props.story, ['story-template'], '');
  const slug = get(props.story, ['slug'], 'NA');
  const sectionName = slug !== 'NA' ? slug.split('/')[0] : 'NA';
  const contentType =
    storyTemplate === 'video' || storyTemplate === 'ndtv-video' ? 'video' : 'article';
  const source = get(
    props.story,
    ['metadata', 'story-attributes', 'syndicatedfrom', '0'],
    'NDTV Profit',
  );

  const authors = authorName;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (shareContainerRef.current && !shareContainerRef.current.contains(event.target)) {
        setIsShareModalOpen(false);
      }
    };

    const handleScroll = () => {
      setIsShareModalOpen(false);
    };

    if (isShareModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('scroll', handleScroll);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isShareModalOpen]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const datalayerFields = {
    page_title: props?.story?.headline || 'NA',
    ContentType: contentType,
    page_template: 'story',
    section_name: 'story-header',
    authors: authors || 'NA',
    agency: source || 'NA',
    category: sectionName || 'NA',
    publish_date: publishedDate || 'NA',
    publish_time: publishedTime || 'NA',
    last_update_date: updatedAtDateTime || 'NA',
    tvc_article_id: id || 'NA',
    sub_category: 'NA',
    user_id: userData?.userProfile?.userId || 'NA',
    user_status: getUserStatus(),
    login_type: userData?.isLoggedIn ? 'logged-in' : 'logged-out',
  };

  const getHeroImageCaption = () => {
    let heroImageCaption =
      get(props, ['story', 'hero-image-caption'], null) ||
      get(
        props,
        ['story', 'alternative', 'home', 'default', 'hero-image', 'hero-image-caption'],
        '',
      );

    heroImageCaption =
      heroImageCaption &&
      heroImageCaption
        .replace(/<div class="paragraphs"><p>/gi, '')
        .split('</p></div>')
        .join(' ')
        .split('<br>')
        .join(' ');

    return heroImageCaption ? (
      <div styleName="hero-image-caption" dangerouslySetInnerHTML={{ __html: heroImageCaption }} />
    ) : (
      <></>
    );
  };

  const PublisherAndSocialShareSection = () => {
    const handleShareClick = () => {
      if (isMobile) {
        if (navigator.share) {
          navigator.share({
            title: props?.story?.title || document?.title || '',
            text: props?.story?.summary || '',
            url: `${props?.publisherUrl}/${props?.story?.slug}` || '#'
          })
          .catch(error => console.log('Error sharing:', error));
        } else {
          setIsShareModalOpen(!isShareModalOpen);
        }
      } else {
        setIsShareModalOpen(!isShareModalOpen);
      }
    };
    return (
      <div>
        <div styleName="story-content-head">
          <div styleName="clock-icon-border" className="desktop-only">
            <ClockOutlineIcon size="24px" color="var(--fourth-color)" />
          </div>
          <div styleName="clock-icon-mobile" className="mobile-only">
            <ClockOutlineIcon size="18px" color="var(--fourth-color)" />
          </div>
          <div styleName="author-and-published-time">
            <span className="desktop-only">Updated On</span>
            <span
              styleName="story-published-date"
              dateTime={new Date(props.story['last-published-at'])
                .toISOString()
                .replace('Z', '+05:30')}
            >
              {getStoryDate(props.story['last-published-at'])}{' '}
              <span className="icon-ic_info_outline" styleName="story-date-info-icon">
                <span className="i-icon">i</span>
              </span>
              <div styleName="popover-story-dates">
                <PublishedInfo
                  publishedAt={props.story['first-published-at']}
                  updatedAt={props.story['last-published-at']}
                />
              </div>
            </span>
          </div>
          <div styleName="share-floating-expanded" ref={shareContainerRef}>
            <div
              styleName="g-news"
              onClick={() => {
                window.open(
                  'https://news.google.com/publications/CAAqBwgKMKr0kAsw9JylAw?hl=en-IN&gl=IN&ceid=IN%3Aen',
                  '_blank',
                ),
                  shareIconDL('Google News', props?.story, userData, 'story header');
              }}
            >
              <i styleName="icon" className="icon-ic_google-news" />
            </div>
            <div styleName="icon-wrapper-bookmark">
              <BookmarkShare />
            </div>
            <div styleName="share-btn"
              onClick={() => handleShareClick()}
              onMouseEnter={() => setIsShareModalOpen(true)}
            >
              <Icon path={mdiShare} size='24px' />
            </div>
            {isShareModalOpen && !isMobile && (
              <Share
                datalayerFields={datalayerFields}
                title={props.story.headline}
                url={props.story.slug}
                summary={props.story.summary}
                services={['whatsapp', 'twitter', 'facebook', 'telegram']}
                onClick={storyShareClickHandler(
                  props.story['story-content-id'],
                  `${props.publisherUrl}/${props.story.slug}`,
                )}
                source="story-content-head"
                expanded={true}
                alignType="horizontal"
                showToggleWhenExpanded={false}
                bookmark={false}
                storyId={get(props, ['story', 'id'])}
                isModal={true}
              />
            )}
          </div>

          {showFloatingSocial ? (
            <ShareFloatingExpanded
              datalayerFields={datalayerFields}
              title={props.story.headline}
              url={props.story.slug}
              summary={props.story.summary}
              services={['whatsapp', 'twitter', 'facebook', 'telegram', 'copy']}
              onClick={storyShareClickHandler(
                props.story['story-content-id'],
                `${props.publisherUrl}/${props.story.slug}`,
              )}
              storyId={get(props, ['story', 'id'])}
              bookmark={props.bookmark}
              story={props.story}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const [stories, setStories] = useState([]);

  const noHeroImage =
    get(props, ['story', 'hero-image-s3-key']) === null &&
    !isEmpty(get(props, ['story', 'alternative'])) &&
    !!get(props, ['story', 'alternative', 'home', 'default', 'hero-image', 'hero-image-s3-key']);

  const SLIDER_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 4,
    focusOnSelect: false,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToScroll: 4,
        },
      },
    ],
    nextArrow: (
      <div>
        <RightArrowSmall color="fourth-color" />
      </div>
    ),
    prevArrow: (
      <div>
        <LeftArrowSmall color="fourth-color" />
      </div>
    ),
  };

  useEffect(() => {
    if(test_page){
      setTopIndices(TopIndices);
      setStories(Videos);
      setPremium(PremiumStories);
    } else {
      fetch(
        `${props?.integrations?.['market-api']?.['client-host']}/feapi/markets/indices/indian-indices?duration=1D`,
      )
        .then((res) => res.json())
        .then((res) => setTopIndices(res?.data));

      fetch(`/api/v1/collections/must-watch-videos?limit=4`)
      .then((res) => res.json())
      .then((res) => {
        setStories(res.items.map((item) => item.story));
      });
      fetch('/api/v1/collections/premium?item-type=story')
      .then((res) => res.json())
      .then((res) => {
        setPremium(res);
      });
    }
  }, []);

  let scrollPercentage = 0;
  let hasScrolled25 = false;
  let hasScrolled50 = false;
  let hasScrolled75 = false;
  let hasScrolled100 = false;

  const calculateScrollPercentage = () => {
    if (typeof window !== 'undefined') {
      const scrollTop = window.scrollY;
      const documentHeight = document.documentElement.scrollHeight - window.innerHeight;

      if (documentHeight > 0) {
        scrollPercentage = (scrollTop / documentHeight) * 100;
        localStorage.setItem('scrollPercentage', scrollPercentage);
      } else {
        scrollPercentage = 0;
        localStorage.setItem('scrollPercentage', scrollPercentage);
      }
    }
  };

  const pushScrollDataToDataLayer = (scrollMilestone) => {
    if (window.dataLayer) {
      const formattedPageType =
        pageType === 'story-page'
          ? 'story'
          : pageType === 'home-page'
            ? 'home-page'
            : 'section-page';
      const userProperty = JSON.parse(localStorage.getItem('local_user_property'));
      const userId = userProperty && userProperty.user_id ? userProperty.user_id : 'NA';
      const loginType =
        userProperty && userProperty.login_status ? userProperty.login_status : 'logged-out';

      const baseData = {
        event: 'page_scroll',
        scroll_percent: scrollMilestone + '%',
        page_template: formattedPageType || 'NA',
        user_id: userId,
        user_status: getUserStatus(),
        login_type: loginType,
        language_hit: 'english',
        Platform: isMobile ? 'mobile' : 'desktop',
        SiteName: 'https://www.ndtvprofit.com',
      };

      window.dataLayer.push(baseData);
    } else {
      console.error('Error: window.dataLayer is not available');
    }
  };

  const handleScroll = () => {
    calculateScrollPercentage();
    if (scrollPercentage >= 25 && !hasScrolled25) {
      pushScrollDataToDataLayer(25);
      hasScrolled25 = true;
    }
    if (scrollPercentage >= 50 && !hasScrolled50) {
      pushScrollDataToDataLayer(50);
      hasScrolled50 = true;
    }
    if (scrollPercentage >= 75 && !hasScrolled75) {
      pushScrollDataToDataLayer(75);
      hasScrolled75 = true;
    }
    if (scrollPercentage >= 100 && !hasScrolled100) {
      pushScrollDataToDataLayer(100);
      hasScrolled100 = true;
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [pageType]);

  const isBudgetStory =
    get(props, ['story', 'sections'])
      .map((item) => item.slug)
      .includes('budget') ||
    get(props, ['story', 'sections'])
      .map((item) => item.slug)
      .includes('budget-2023') ||
    get(props, ['story', 'sections'])
      .map((item) => item.slug)
      .includes('budget-2024') ||
    get(props, ['story', 'sections'])
      .map((item) => item.slug)
      .includes('budget-2025');

  return (
    <>
      <BreadcrumbBasic story={props?.story} />
      {isBudgetStory && props?.budgetBanner && (
        <div styleName="story-budgetstrip-wrapper">
          <BudgetStrip2023 />
        </div>
      )}
      <article id={`story-${props.story.id}`}>
        <>
          {!isGeoBlock && (
            <div styleName="story-content" className={textSelection}>
              <div styleName="story-header-text premium-container">
                <div styleName="heading">
                  <span styleName="title">{getSection(props?.story) || 'Exclusive'}</span>
                  {isStoryExclusive(props?.story) && (
                    <img src="/icons/premium.svg" height="29px" width="auto" />
                  )}
                </div>
                <h1 styleName="story-headline">{props.story.headline}</h1>

                <div styleName="story-subtext-wrapper">
                  <h2 styleName="story-subtext">{props.story.summary}</h2>
                </div>
                <div styleName="author-section-premium">
                  <Authors
                    storyId={props.story.id}
                    authors={props.story.authors}
                    datalayerFields={datalayerFields}
                    alt="BQ"
                  />
                </div>
                <TrackVisibility
                  onHide={() => setShowFloatingSocial(true)}
                  onVisible={() => setShowFloatingSocial(false)}
                >
                  <div className="dummy-div-for-intersection-obeserver"></div>
                </TrackVisibility>
              </div>
              <div styleName="premium-container publisher-section-premium">
                {PublisherAndSocialShareSection()}
              </div>
            </div>
          )}
        </>
        <div styleName="story-except-headline" className="container">
          {topIndices && topIndices.length > 0 ? (
            <div styleName="top-indices">
              <h4 styleName="top-indices-title">TOP INDICES</h4>
              <div styleName="indices"></div>
              {topIndices?.slice(0, 5)?.map((indice, index) => {
                const percentage = get(indice, ['price-movement', 'chgp'], '');
                const currentPrice = get(indice, ['price-movement', 'current-price'], '');
                return (
                  <BQLink href={'/markets?src=strypge'}>
                    <div styleName="indice">
                      <h5 styleName="indice-text">{indice.name}</h5>
                      <div styleName="indice-movement">
                        <div>
                          <span
                            style={{
                              color:
                                percentage > 0 ? 'var(--success-color)' : 'var(--negative-red)',
                              fontFamily: 'var(--font3)',
                            }}
                          >
                            <SvgIcon
                              type={`${percentage > 0 ? 'upArrowCircle' : 'downArrowCircle'}`}
                              source="image"
                              height="13"
                              width="13"
                            />
                            {Number(currentPrice).toFixed(2)}
                          </span>
                        </div>
                        <span style={{ fontFamily: 'var(--font3)' }}>
                          {percentage > 0
                            ? `+${Number(percentage).toFixed(2)}`
                            : Number(percentage).toFixed(2)}
                          %
                        </span>
                      </div>
                    </div>
                  </BQLink>
                );
              })}
              <div onClick={() => watchLiveInteraction(userData, pageType, 'left-side-bar')}>
                <LiveTvSmallBanner className="desktop-only" />
              </div>
            </div>
          ) : (
            <div />
          )}
          <div styleName="left-sidebar">
            <div className="reddit-card " />
            <div className="reddit-embed" />
            <canvas className="hide-element" width="1" height="1" />
            <object className="hide-element" width="1" height="1" />
            <>
              <div styleName="story-header">
                {!isGeoBlock && !noHeroImage && (
                  <div styleName="shimmer-card">
                    <div styleName="hero-image-container">
                      <CardImage
                        story={props.story}
                        aspectRatio={[16, 9]}
                        imageType="image5x2"
                        showHeroDetails={true}
                        isLazy={false}
                        fetchPriority="high"
                        loading="eager"
                      />
                      {getHeroImageCaption()}
                    </div>
                  </div>
                )}

                {!isGeoBlock ? (
                  <div styleName={`body-container ${noHeroImage ? 'mt-neg' : ''}`}>
                    <div>{renderCards(props?.story, props?.story?.cards)}</div>
                    <div className="mobile-only">
                      <MailmodoNewsLetter
                        title={newsletter.title}
                        heading={newsletter.subTitle}
                        footerText1="We Promise. No Spam !"
                        footerText2="View All Newsletters"
                        page="story"
                        checkStory={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div styleName="body-container">
                    <ContentNotAvailable />
                  </div>
                )}
              </div>
            </>
            <div style={{ position: 'sticky', top: '100px' }}>
              <Footer
                {...props}
                PublisherAndSocialShareSection={PublisherAndSocialShareSection}
                isGeoBlock={isGeoBlock}
                isPremiumTemplate={true}
              />
            </div>
            {stories && (
              <>
                <div className="desktop-only" styleName="desktop-videos-section">
                  <div className="desktop-only" style={{ position: 'relative' }}>
                    <MailmodoNewsLetter
                      title={newsletter.title}
                      heading={newsletter.subTitle}
                      footerText1="No Spam. Just great journalism in your inbox"
                      footerText2="View All Newsletters"
                      page="story"
                      checkStory={true}
                    />
                  </div>
                  <VideoTitle />
                  <Slider {...SLIDER_SETTINGS}>
                    {stories.slice(0, 4).map((story) => {
                      return (
                        story && (
                          <div styleName="story-container" key={story.id}>
                            <StoryCardVerticalWithImageDate
                              story={story}
                              imageType="image6x4"
                              aspectRatio={[6, 4]}
                            />
                          </div>
                        )
                      );
                    })}
                  </Slider>
                </div>
                <div className="mobile-only" styleName="story-bottom-videos">
                  {stories.length > 0 && <VideosWidget stories={stories} />}
                </div>
              </>
            )}
          </div>
          <div id="top-five-stories-today" styleName="top-five-stories-today">
            <MoreLikeThisWidget story={props.story} type="premium" />
          </div>
        </div>
      </article>
      {premium && (
        <div id="exclusives-premium-section" className="container">
          <ExclusivesStorySliderWidget collection={premium} />
        </div>
      )}
    </>
  );
};

PremiumStory.propTypes = {
  publisherUrl: string.isRequired,
  story: objectOf(any).isRequired,
  content: element.isRequired,
  textSelectionEnabled: bool.isRequired,
  cardOrder: string,
  orderChanged: func,
  isLoading: bool,
  amazon_polly: bool,
  bookmark: bool,
  budgetBanner: bool,
};

PremiumStory.defaultProps = {
  cardOrder: '',
  orderChanged: () => {},
};

const mapStateToProps = (state) => ({
  publisherUrl: state.qt.config['sketches-host'],
  integrations: state.qt.config.integrations,
  textSelectionEnabled: state.qt.config.features.story.text_selection.enabled,
  amazon_polly: get(state.qt, ['config', 'features', 'amazon_polly', 'enabled'], false),
  bookmark: get(state, ['qt', 'config', 'features', 'bookmark', 'enabled'], false),
  googlenews_icon: get(state, ['qt', 'config', 'publisher', 'cdn-images', 'googlenews'], ''),
  budgetBanner: get(state.qt, ['config', 'features', 'budget', 'story-budget-banner'], false),
  newsLetterList: get(state, ['qt', 'config', 'features', 'newsLetterList'], {}),
});

export default connect(mapStateToProps, {})(PremiumStory);
