import React from 'react'
import { BQLink } from '../../bqlink';

import "./live-tv-big-icon.m.css";

const LiveTvSmallBanner = ({ className = '' }) => {
  return (
    <BQLink href={`/live-tv?src=subs_story`} styleName="live-tv-banner-container" className={className} target='_blank'>
      <img src='/icons/live-tv-banner.svg' alt='group' styleName="live-tv-icon-banner" />
    </BQLink>
  )
}

export default LiveTvSmallBanner;
