import React from 'react';
import { objectOf, string, any, func, array } from 'prop-types';
import { ResponsiveImage } from '@quintype/components';

import './story-image-viewer.m.css';

class StoryImageViewer extends React.Component {
  constructor(props) {
    super(props);
    this.showResponsiveImage = this.showResponsiveImage.bind(this);
  }

  showResponsiveImage(element, aspectRatio, caption, imgClassName, openLightbox) {
    return (
      <ResponsiveImage
        slug={element['image-s3-key']}
        metadata={element.metadata}
        aspect-ratio={aspectRatio}
        defaultWidth={480}
        widths={[250, 480, 640, 800, 960]}
        sizes="(max-width: 600px) 48vw, (max-width: 990px) 52vw, 28vw"
        imgParams={{}}
        alt={caption}
        className={imgClassName && imgClassName.responsiveImage}
        onClick={openLightbox}
        loading="lazy"
      />
    );
  }

  render() {
    const {
      imgClassName,
      imgStyleName,
      aspectRatio,
      type,
      externalImageSrc,
      openLightbox,
      element,
      caption,
      externalImageSrcset,
    } = this.props;

    return (
      <div styleName="story-image-viewer">
        <figure
          styleName={imgStyleName && imgStyleName.figure}
          className={imgClassName && imgClassName.figure}
          data-testid={`${type}-storyImageViewer`}
          onClick={openLightbox}
        >
          {type === null && (
            <span
              data-testid="expand-icon"
              className="icon-ic_fullscreen"
              styleName="expand-icon"
              onClick={this.openLightbox}
            />
          )}
          {type === null ? (
            externalImageSrc ? (
              <img
                data-testid="normalImage"
                className={imgClassName && imgClassName.img}
                src={externalImageSrc}
                alt={caption}
                srcSet={externalImageSrcset}
              />
            ) : (
              this.showResponsiveImage(element, aspectRatio, caption, imgClassName, openLightbox)
            )
          ) : (
            this.showResponsiveImage(element, aspectRatio, caption, imgClassName, openLightbox)
          )}
        </figure>
        {element.title ? (
          <figcaption
            styleName={imgStyleName && imgStyleName.figcaption}
            className={imgClassName && imgClassName.figcaption}
          >
            <div dangerouslySetInnerHTML={{ __html: element.title }} />
          </figcaption>
        ) : null}
      </div>
    );
  }
}
StoryImageViewer.propTypes = {
  imgClassName: objectOf(string),
  imgStyleName: objectOf(string),
  aspectRatio: array,
  type: string,
  externalImageSrc: string,
  openLightbox: func.isRequired,
  element: objectOf(any).isRequired,
  caption: string,
  externalImageSrcset: any,
};

export default StoryImageViewer;
