import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import AdWithPlaceholder from '../../molecules/ad-with-placeholder';
import VideosWidget from '../../molecules/videos-widget';
import { isMobile } from 'react-device-detect';
import MailmodoNewsLetter from '../../molecules/newsletter/mailmodo-news-letter';
import './sidebar.module.css';
import ExpandVideosWidget from '../expand-videos-widget/expand-videos-widget';
import MoreLikeThisWidget from '../more-like-this-widget';
import { connect } from 'react-redux';
import get from 'lodash/get';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleClassName: 'sidebar-container',
    };
    this.toggleClass = this.toggleClass.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.toggleClass);
    setTimeout(this.toggleClass, 20000);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleClass);
  }

  toggleClass() {
    this.setState({ toggleClassName: 'sidebar-container' });
  }

  render() {
    const newsletter = this.props?.newsLetterList?.find(n => n.data_key === 'Talking');

    return (
      <div style={{ display: 'unset' }} id={`sidebar-${this.props.story.id}`}>
        <div styleName="newsletter-wrapper">
          <MailmodoNewsLetter
            title={newsletter.title}
            heading={newsletter.subTitle}
            footerText1="We Promise. No Spam !"
            footerText2="View All Newsletters"
            page="story-sidebar"
          />
        </div>
        <ExpandVideosWidget sectionName="story-sidebar" />
        {!isMobile && (
          <div className="responsive-ad content-center desktop-only" styleName="mid300x250ad">
            <AdWithPlaceholder
              adtype="midMrec"
              height="280px"
              width="336px"
              adUnit="Mid_300x250_1"
              sizes={[
                [300, 250],
                [336, 280],
              ]}
            />
          </div>
        )}
        <div>
          <div styleName={`ad-sidenotes ${this.state.toggleClassName}`}>
            <MoreLikeThisWidget story={this.props.story} />
          </div>
        </div>
        <MediaQuery minWidth={1200}>
          <div styleName="sticky-ad">
            {!isMobile && (
              <div className="responsive-ad content-center desktop-only">
                <AdWithPlaceholder
                  adtype="LiveBlogRightSidebarFilmstrip"
                  height="280px"
                  width="336px"
                  adUnit="Mid_300x250_2"
                  sizes={[
                    [300, 250],
                    [336, 280],
                  ]}
                />
              </div>
            )}
            <VideosWidget  stories={this.props?.videos}/>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

Sidebar.propTypes = {
  story: PropTypes.objectOf(PropTypes.any).isRequired,
  datalayerFields: PropTypes.objectOf(PropTypes.any).isRequired,
  mostRead: PropTypes.object,
  isLiveBlog: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  newsLetterList: get(state, ['qt', 'config', 'features', 'newsLetterList'], []),
});

export default connect(mapStateToProps, {})(Sidebar);
