const theme = {};

theme.container = {
  background: '#222',
  gutter: {
    horizontal: 10,
    vertical: 10,
  },
  zIndex: 2001,
};

theme.header = {
  fontSize: '16px',
  height: '3.500em',
  backgroundColor: '#000',
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  justifyContent: 'center',
};

theme.close = {
  fill: 'white',
  margin: 0,
  position: 'absolute',
  right: '4px',
  top: '6px',
  width: '45px',
  height: '45px',
};

theme.figure = {
  marginTop: '55px',
};

theme.image = {
  maxHeight: '85vh',
};

theme.footer = {
  color: '#999',
  marginTop: '10px',
  fontSize: '12px',
  textAlign: 'center',
  count: {
    color: 'rgba(255, 255, 255, 0.75)',
    fontSize: '12px',
  },
  height: 40,
  gutter: {
    horizontal: 0,
    vertical: 5,
  },
};

theme.thumbnail = {
  activeBorderColor: 'white',
  size: 50,
  gutter: 2,
};

theme.arrow = {
  background: 'none',
  fill: 'white',
  height: 120,
};

export default theme;
