import React, { useState, useEffect } from 'react';
import { objectOf, any, string } from 'prop-types';
import find from 'lodash/find';
import get from 'lodash/get';
import SnapshotHeroImage from '../../../atoms/snapshot-hero-image';
import { getStoryHeaderData } from '../../../../helpers/story-helpers';
import './story-snapshot.m.css';

const StorySnapshot = ({ element, variant = 'default' }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    getLinkedStory(element.metadata['linked-story-id']);
  }, []);

  const getLinkedStoryText = cards => {
    const firstTextCard = find(
      cards,
      card =>
        card['story-elements'].findIndex(elt => elt.type === 'text' && elt.subtype === null) > -1
    );
    return get(firstTextCard, ['metadata', 'social-share', 'message'], 'Hello');
  };

  const getLinkedStory = id => {
    fetch(`/api/v1/stories/${id}`)
      .then(res => res.json())
      .then(json => setData(json));
  };
  const { story } = data;
  if (story) {
    const { headline, heroImageData, storyTemplateAttribute } = getStoryHeaderData(story);

    return (
      <div styleName="story-snapshot-template">
        {variant === 'default' ? (
          <a target="_blank" href={story.slug} styleName="story-snapshot" rel="noopener noreferrer">
            <div styleName="story-snapshot-image-heading-content">
              {heroImageData.heroImageS3Key && storyTemplateAttribute !== 'without-hero-image' && (
                <SnapshotHeroImage
                  slug={heroImageData.heroImageS3Key}
                  metadata={heroImageData.heroImageMetaData}
                  styleName="image-container"
                />
              )}
              <div styleName="story-snapshot-heading-content">
                <h4 styleName="heading">{headline}</h4>
                <span styleName="read-more">Read More</span>
              </div>
            </div>
          </a>
        ) : (
          <a
            target="_blank"
            href={story.slug}
            styleName="story-snapshot bg-image"
            rel="noopener noreferrer"
          >
            <h4 styleName="heading">{headline}</h4>
            {heroImageData.heroImageS3Key && storyTemplateAttribute !== 'without-hero-image' && (
              <SnapshotHeroImage
                slug={heroImageData.heroImageS3Key}
                metadata={heroImageData.heroImageMetaData}
                styleName="image-container-bg"
              />
            )}
            <div styleName="content" className="truncate-overflow">
              {getLinkedStoryText(story.cards)}
            </div>
            <div styleName="read-more-wrapper">
              <span styleName="read-more">Read More</span>
            </div>
          </a>
        )}
      </div>
    );
  }
  return null;
};

StorySnapshot.propTypes = {
  element: objectOf(any).isRequired,
  variant: string
};

export default StorySnapshot;
